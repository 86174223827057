/** @format */

import React from 'react'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import {
	FacebookOutlined,
	TwitterOutlined,
	InstagramOutlined,
	LinkedinOutlined,
	YoutubeOutlined,
} from '@ant-design/icons'

import Spacer from '../Spacer'

import './style.css'

export default function FooterMiddle() {
	return (
		<div className='est-footer-middle-global-container'>
			<Row className='est-footer-middle-main-container'>
				<Col xs={24} sm={6} md={6} lg={5} xl={6}>
					<div className='est-footer-middle-container'>
						<h3 className='est-footer-middle-title-primary'>
							PRÚJULA es una empresa puertorriqueña pionera en compartir nuestra tierra y
							nuestra cultura con amantes y protectores de la naturaleza de Puerto Rico y
							todas partes del mundo.
						</h3>
					</div>
				</Col>
				<Col xs={8} sm={6} md={6} lg={6} xl={7}>
					<div className='est-footer-middle-container-left'>
						<p className='est-footer-middle-title'>Inversores</p>
						<p className='est-footer-middle-title'>Locaciones</p>
						<p className='est-footer-middle-title'>Media Room</p>
						<p className='est-footer-middle-title'>Sostenibilidad</p>
						<p className='est-footer-middle-title'>Ecoturismo</p>
					</div>
				</Col>
				<Col xs={8} sm={6} md={6} lg={6} xl={7}>
					<div className='est-footer-middle-container-center'>
						<p className='est-footer-middle-title'>FAQ</p>
						<p className='est-footer-middle-title'>Afiliados</p>
						<p className='est-footer-middle-title'>Soporte</p>
						<p className='est-footer-middle-title'>Blog</p>
						<p className='est-footer-middle-title'>Carreras</p>
					</div>
				</Col>
				<Spacer />
				<Col xs={7} sm={5} md={4} lg={3} xl={3}>
					<div className='est-footer-middle-container-right'>
						<p className='est-footer-middle-title'>
							<FacebookOutlined className='icon-rrss' />
							Facebook
						</p>
						<p className='est-footer-middle-title'>
							<TwitterOutlined className='icon-rrss' />
							Twitter
						</p>
						<p className='est-footer-middle-title'>
							<InstagramOutlined className='icon-rrss' />
							Instagram
						</p>
						<p className='est-footer-middle-title'>
							<LinkedinOutlined className='icon-rrss' />
							LinkedIn
						</p>
						<p className='est-footer-middle-title'>
							<YoutubeOutlined className='icon-rrss' />
							Youtube
						</p>
					</div>
				</Col>
			</Row>
		</div>
	)
}
