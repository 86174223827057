/** @format */
import React, { useState, useEffect } from 'react'

import { setGlobal, useGlobal } from 'reactn'

import Modal from 'antd/lib/modal'
import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import notification from 'antd/lib/notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import Input from '../../../../../../components/Common/Inputs/Normal'
import Image from '../../../../../../components/Common/Image'

import { ENV_CORE } from '../../../../../../components/Common/Hooks/Variables/Enviroment'

import Uploadphoto from './components/UploadPhoto'

import ProfileDetail from '../../services'

import ProfileUpdate from './services'

import './style.css'

export default function ModalLoginUser(props) {
	const [form] = Form.useForm()
	const [isModalUser, setModalUser] = useState(false)
	const [isNewPhoto, setNewPhoto] = useState(null)
	const [isLoading] = useGlobal('LoadingButtonProfile')

	const handleModalEditUser = () => {
		if (isModalUser) {
			setModalUser(false)
		} else {
			setModalUser(true)
		}
	}

	const handleEditUser = async (item) => {
		setGlobal({ LoadingButtonProfile: true })
		if (!props.isUser.foto) {
			if (!isNewPhoto) {
				setGlobal({ LoadingButtonProfile: false })
				notification['warning']({
					message: 'Advertencia:',
					description: 'Debe subir una imagen para el perfil.',
				})
				return
			} else {
				item.updFoto = isNewPhoto
			}
		} else {
			if (isNewPhoto) {
				item.updFoto = isNewPhoto
			} else {
				item.updFoto = props.isUser.foto
			}
		}
		ProfileUpdate(item).then((response) => {
			if (response) {
				ProfileDetail(item.updId).then((responseUser) => {
					setGlobal({
						DataProfile: responseUser,
					})
				})
			}
		})
		setGlobal({ LoadingButtonProfile: false })
		setModalUser(false)
	}

	const handleAddProfileImage = (item) => {
		setNewPhoto(`${ENV_CORE}/${item.file}`)
	}

	const handleDeleteProfileImage = (item) => {
		setNewPhoto(null)
	}

	useEffect(() => {}, [])

	return (
		<>
			<Button className='est-auth-edit-profile-button' onClick={() => handleModalEditUser()}>
				Editar
			</Button>
			<Modal
				forceRender
				wrapClassName='est-user-modal-container'
				maskClosable={true}
				width='700px'
				centered
				visible={isModalUser}
				onCancel={() => handleModalEditUser()}
				okText='Confirmar'
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}>
				<h3 className='est-auth-edit-profile-modal-title'>Editar Datos de usuario</h3>
				<Form
					form={form}
					initialValues={{
						updName: props.isUser.nombre,
						updLast: props.isUser.apellido,
						updEmail: props.isUser.email,
						updId: props.isUser.id,
						updPhone: props.isUser.telefono,
						updIdioma: 'en',
					}}
					name='user_login'
					onFinish={handleEditUser}>
					<div className='est-auth-login-form-container'>
						<Row>
							<Col span={12} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>Idioma</h4>
								<Input
									className={'est-auth-login-field-input'}
									inputName={'updIdioma'}
									inputNameLabel={'Idioma'}
									inputNameRule={true}
									inputNameMessage={'Nombre es obligatorio.'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={'rulesName'}
									disabled={true}
								/>
							</Col>
							<Col span={12} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>ID</h4>
								<Input
									className={'est-auth-login-field-input'}
									inputName={'updId'}
									inputNameLabel={'Nombre'}
									inputNameRule={true}
									inputNameMessage={'Nombre es obligatorio.'}
									inputNameType={'number'}
									inputNameIcon={''}
									inputNameRules={'rulesName'}
									disabled={true}
								/>
							</Col>
							<Col span={12} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>Nombre</h4>
								<Input
									className={'est-auth-login-field-input'}
									inputName={'updName'}
									inputNameLabel={'Nombre'}
									inputNameRule={true}
									inputNameMessage={'Nombre es obligatorio.'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={'rulesName'}
									disabled={props.isUser.modo === 'directo' ? false : true}
								/>
							</Col>
							<Col span={12} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>Apellido</h4>
								<Input
									className={'est-auth-login-field-input'}
									inputName={'updLast'}
									inputNameLabel={'Apellido'}
									inputNameRule={true}
									inputNameMessage={'Nombre es obligatorio.'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={'rulesName'}
									disabled={props.isUser.modo === 'directo' ? false : true}
								/>
							</Col>
							<Col span={24} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>Correo Electrónico</h4>
								<Input
									className={'est-auth-login-field-input'}
									inputName={'updEmail'}
									inputNameLabel={'Correo electrónico'}
									inputNameRule={true}
									inputNameMessage={'E-mail es obligatorio'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={'rulesEmail'}
									disabled={true}
								/>
							</Col>
							<Col span={24} className='est-login-form-text-container'>
								<h4 className='est-login-form-text'>Teléfono</h4>
								<Input
									className={'est-auth-login-field-input'}
									inputName={'updPhone'}
									inputNameLabel={'Teléfono'}
									inputNameRule={true}
									inputNameMessage={'Telefono es obligatorio'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={'rulesText'}
								/>
							</Col>
							<Col span={12} className='est-login-form-upload-photo-container'>
								<Uploadphoto
									deleteItemImage={(data) => handleDeleteProfileImage(data)}
									addItemImage={(data) => handleAddProfileImage(data)}
								/>
								<div className='est-profile-edit-modal-image-container'>
									{props.isUser.foto && (
										<>
											<h4 className='est-profile-edit-modal-image-text'>
												Imagen actual de su perfil:
											</h4>
											<Image
												classImg={'est-profile-edit-modal-image'}
												image={props.isUser.foto}
												alt={'Imagen Profile'}
												title={'Imagen Profile'}
											/>
										</>
									)}
								</div>
							</Col>
						</Row>
						<Form.Item>
							<div>
								<div className='est-user-modal-button-main-container'>
									<Button
										className='est-user-modal-button-main'
										type='primary'
										htmlType={'submit'}
										loading={isLoading}>
										Aceptar
									</Button>
								</div>
							</div>
						</Form.Item>
					</div>
				</Form>
			</Modal>
		</>
	)
}
