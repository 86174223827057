/** @format */

import React from 'react'

import { Link } from 'react-router-dom'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import './style.css'

export default function PageNotFound() {
	return (
		<div className='est-not-found-global-container'>
			<div className='est-not-found-main-container'>
				<h3 className='est-not-found-icon'>
					<ExclamationCircleOutlined />
				</h3>
				<h3 className='est-not-found-title'>¡Lo sentimos!</h3>
				<h3 className='est-not-found-subtitle'>Página no encontrada.</h3>

				<div className='est-not-found-button-container'>
					<Link to='/' className='est-not-found-button'>
						Volver
					</Link>
				</div>
			</div>
		</div>
	)
}
