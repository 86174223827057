/** @format */

import React from 'react'

import MainNavigation from '../../components/Common/MainNavigation'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'

import InputCustom from '../../../src/components/Common/Inputs/Normal'

import {
	FolderOutlined,
	WechatOutlined,
	HeartOutlined,
	FacebookFilled,
	TwitterSquareFilled,
	InstagramOutlined,
	PlusCircleFilled,
	SearchOutlined,
} from '@ant-design/icons'

import Image from '../../components/Common/Image'
import BlogMainImage from '../../../src/img/blog/youngcouple-blog-simple.png'
import CommentProfilePhoto from '../../../src/img/detail/profile-example.png'
import SearchBlogPost from '../../../src/img/blog/4-people-camping.png'

import './style.css'

export default function Blog() {
	const { TextArea } = Input
	return (
		<div className='est-blog-detail-global-container'>
			<MainNavigation
				title={'Artículo'}
				linkPrevTitle={'Home'}
				linkNextTitle={'Blog'}
				linkPrev={'/'}
				linkNext={'/'}
			/>
			<div className='est-blog-detail-main-container'>
				<Row>
					<Col span={18} className='est-blog-detail-left-global-container'>
						<div className='est-blog-detail-title-img-container'>
							<h3 className='est-blog-detail-title'>
								Fusce porta tincidunt neque, vel egestas nunc tristique vitae. Sed.
							</h3>
							<div className='est-blog-detail-subtitle-container'>
								<h4 className='est-blog-detail-category-text'>
									<FolderOutlined /> Categoría
								</h4>
								<h4 className='est-blog-detail-category-icon'>
									<WechatOutlined /> 12
								</h4>
								<h4 className='est-blog-detail-category-icon'>
									<HeartOutlined /> 6
								</h4>
							</div>
							<div className='est-blog-detail-img-container'>
								<Image
									classImg={'est-blog-main-img'}
									image={BlogMainImage}
									alt={'Profile photo'}
									title={'Profile photo'}
								/>
								<div className='est-blog-detail-img-date'>
									<h3 className='est-blog-detail-img-date-title'>26</h3>
									<h3 className='est-blog-detail-img-date-subtitle'>04</h3>
								</div>
							</div>
						</div>
						<div className='est-blog-detail-text-container'>
							<p className='est-blog-detail-text'>
								Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
								nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
								wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
								lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure
								dolor in hendrerit in vulputate.
							</p>
							<ul className='est-blog-detail-ul'>
								<li>Est ei erat mucius.</li>
								<li>At quaeque adversarium ius.</li>
								<li>Persius verterem. Sit summo</li>
								<li>Eam et fugit complectitur, vis</li>
							</ul>
							<p className='est-blog-detail-text'>
								Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
								nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
								wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
								lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure
								dolor in hendrerit in vulputate velit esse molestie consequat, vel illum
								dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio
								dignissim qui blandit praesent luptatum
							</p>
							<p className='est-blog-detail-quote'>
								"Mei eu mollis albucius, ex nisl contentiones vix. Duo persius volutpat at,
								cu iuvaret epicuri mei. Duo posse pertinacia no, ex dolor contentiones mea.
								Nec omnium utamur dignissim ne. Mundi lucilius salutandi an sea, ne sea
								aeque iudico comprehensam. Populo delicatissimi ad pri. Ex vitae accusam
								vivendum pro. In mel saperet expetendis."
							</p>
							<p className='est-blog-detail-text'>
								No his munere interesset. At soluta accusam gloriatur eos, ferri commodo sed
								id, ei tollit legere nec. Eum et iudico graecis, cu zzril instructior per,
								usu at augue epicurei. Saepe scaevola takimata vix id. Errem dictas
								posidonium id vis, ne modo affert incorrupte eos. Lorem salutandi eu mea,
								eam in soleat iriure assentior. Tamquam lobortis id qui. Ea sanctus
								democritum mei, per eu alterum electram adversarium. Ea vix probo.
							</p>
						</div>
						<hr className='est-blog-detail-spacer'></hr>
						<div className='est-blog-detail-icon-container'>
							<h3 className='est-blog-detail-rrss'>Compartir</h3>
							<h3 className='est-blog-detail-icon'>
								<FacebookFilled />
							</h3>
							<h3 className='est-blog-detail-icon'>
								<TwitterSquareFilled />
							</h3>
							<h3 className='est-blog-detail-icon'>
								<InstagramOutlined />
							</h3>
						</div>
						<div className='est-blog-detail-comments-container'>
							<h3 className='est-blog-detail-comments-title'>3 comentarios</h3>

							<div className='est-blog-detail-comments-main-container'>
								<div className='est-blog-detail-comments-img-container'>
									<Image
										classImg={'est-blog-detail-comments-img'}
										image={CommentProfilePhoto}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-detail-comments-text-container'>
									<div className='est-blog-detail-comments-title-subtittle-container'>
										<h3 className='est-blog-detail-comments-title'>Barney</h3>
										<h3 className='est-blog-detail-comments-subtitle'>
											Abril 7, 2021 1:33 am
										</h3>
									</div>
									<h3 className='est-blog-detail-comments-text'>
										Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
										Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
										admodum et his,
									</h3>
									<h4 className='est-blog-detail-comments-text-link'>Respuesta</h4>
								</div>
							</div>

							<hr className='est-blog-detail-comments-response-hr'></hr>

							<div className='est-blog-detail-comments-main-container'>
								<div className='est-blog-detail-comments-img-container'>
									<Image
										classImg={'est-blog-detail-comments-img'}
										image={CommentProfilePhoto}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-detail-comments-text-container'>
									<div className='est-blog-detail-comments-title-subtittle-container'>
										<h3 className='est-blog-detail-comments-title'>Lyly</h3>
										<h3 className='est-blog-detail-comments-subtitle'>
											Abril 7, 2021 1:33 am
										</h3>
									</div>
									<h3 className='est-blog-detail-comments-text'>
										Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
										Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
										admodum et his,
									</h3>
									<h4 className='est-blog-detail-comments-text-link'>Respuesta</h4>
								</div>
							</div>

							<div className='est-blog-detail-comments-main-container'>
								<div className='est-blog-detail-comments-img-container'>
									<Image
										classImg={'est-blog-detail-comments-img'}
										image={CommentProfilePhoto}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-detail-comments-text-container'>
									<div className='est-blog-detail-comments-title-subtittle-container'>
										<h3 className='est-blog-detail-comments-title'>Barney</h3>
										<h3 className='est-blog-detail-comments-subtitle'>
											Abril 7, 2021 1:33 am
										</h3>
									</div>
									<h3 className='est-blog-detail-comments-text'>
										Has maiorum habemus detraxit at. Timeam fabulas splendide et his.
										Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre
										admodum et his,
									</h3>
									<h4 className='est-blog-detail-comments-text-link'>Respuesta</h4>
								</div>
							</div>
						</div>
						<hr className='est-blog-detail-spacer'></hr>
						<div className='est-blog-detail-comments-form-container'>
							<h2 className='est-blog-detail-comments-form-title'>Deja tu comentario</h2>
							<h3 className='est-blog-detail-comments-form-subtitle'>
								Su dirección de correo electrónico no será publicada. Los campos
								obligatorios están marcados
							</h3>
							<Form name='blog'>
								<Row className='est-blog-detail-form-global-container'>
									<Col span={12} className='est-blog-detail-form-main-container-one'>
										<InputCustom
											className={'est-blog-detail-form-input'}
											inputName={'first_name'}
											inputNameLabel={'Nombre'}
											inputNameRule={true}
											inputNameMessage={'Ingresa tu nombre'}
											inputNameType={'text'}
											inputNameIcon={''}
											inputNameRules={'rulesFirstName'}
										/>
										<InputCustom
											className={'est-blog-detail-form-input'}
											inputName={'email'}
											inputNameLabel={'Email'}
											inputNameRule={true}
											inputNameMessage={'E-mail es obligatorio'}
											inputNameType={'text'}
											inputNameIcon={''}
											inputNameRules={'rulesEmail'}
										/>
									</Col>
									<Col span={12} className='est-blog-detail-form-main-container-two'>
										<Form.Item name={'message'}>
											<TextArea
												className='est-home-contact-field-input'
												size='large'
												type={'text'}
												placeholder={'Mensaje'}
												rows={3}
											/>
										</Form.Item>
									</Col>
									<Col span={24} className='est-blog-detail-form-button-container'>
										<Button className='est-blog-detail-form-button' htmlType={'submit'}>
											ENVIAR
										</Button>
									</Col>
								</Row>
							</Form>
						</div>

						<div className='est-blog-detail-see-more-global-container'>
							<h3 className='est-blog-detail-see-more-title'>Otras entradas de blog</h3>

							<Row>
								<Col span={8} className='est-blog-detail-see-more-main-container'>
									<Image
										classImg={'est-blog-detail-see-more-img'}
										image={BlogMainImage}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
									<h3 className='est-blog-detail-see-more-article-title'>
										Lorem Ipsum is simply dummy text of the printing.
									</h3>
									<h3 className='est-blog-detail-see-more-subtitle'>
										Por Catherine, Abril 15 2021
									</h3>
									<h3 className='est-blog-detail-see-more-text'>
										Has been the industrys standard dummy text ever since the 1500s, when
										an unknown printer took.
									</h3>
									<h3 className='est-blog-detail-see-more-link'>
										<PlusCircleFilled className='est-blog-detail-see-more-icon' />
										Leer más
									</h3>
								</Col>
								<Col span={8} className='est-blog-detail-see-more-main-container'>
									<Image
										classImg={'est-blog-detail-see-more-img'}
										image={BlogMainImage}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
									<h3 className='est-blog-detail-see-more-article-title'>
										Lorem Ipsum is simply dummy text of the printing.
									</h3>
									<h3 className='est-blog-detail-see-more-subtitle'>
										Por Catherine, Abril 15 2021
									</h3>
									<h3 className='est-blog-detail-see-more-text'>
										Has been the industrys standard dummy text ever since the 1500s, when
										an unknown printer took.
									</h3>
									<h3 className='est-blog-detail-see-more-link'>
										<PlusCircleFilled className='est-blog-detail-see-more-icon' />
										Leer más
									</h3>
								</Col>
								<Col span={8} className='est-blog-detail-see-more-main-container'>
									<Image
										classImg={'est-blog-detail-see-more-img'}
										image={BlogMainImage}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
									<h3 className='est-blog-detail-see-more-article-title'>
										Lorem Ipsum is simply dummy text of the printing.
									</h3>
									<h3 className='est-blog-detail-see-more-subtitle'>
										Por Catherine, Abril 15 2021
									</h3>
									<h3 className='est-blog-detail-see-more-text'>
										Has been the industrys standard dummy text ever since the 1500s, when
										an unknown printer took.
									</h3>
									<h3 className='est-blog-detail-see-more-link'>
										<PlusCircleFilled className='est-blog-detail-see-more-icon' />
										Leer más
									</h3>
								</Col>
							</Row>
						</div>
					</Col>
					<Col span={6} className='est-blog-detail-right-global-container'>
						<div className='est-blog-search-title-input-container'>
							<h3 className='est-blog-search-title'>Buscar</h3>
							<hr className='est-blog-search-spacer'></hr>
							<div className='est-blog-search-input-container'>
								<Input
									size='large'
									placeholder='Ingrese una palabra clave'
									prefix={<SearchOutlined className='est-blog-search-input-icon' />}
								/>
							</div>
						</div>
						<div className='est-blog-search-categories-global-container'>
							<h3 className='est-blog-search-title'>Categorias</h3>
							<hr className='est-blog-search-spacer'></hr>
							<div className='est-blog-search-categories-container'>
								<h3 className='est-blog-search-categories-title'>Gampling</h3>
								<h3 className='est-blog-search-categories-title'>Camper</h3>
								<h3 className='est-blog-search-categories-title'>Mochila</h3>
								<h3 className='est-blog-search-categories-title'>Baúl</h3>
							</div>
						</div>

						<div className='est-blog-search-post-global-container'>
							<h3 className='est-blog-search-title'>Post recientes</h3>
							<hr className='est-blog-search-spacer'></hr>

							<div className='est-blog-search-post-container'>
								<div className='est-blog-search-post-img-container'>
									<Image
										classImg={'est-blog-search-post-img'}
										image={SearchBlogPost}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-search-post-text-container'>
									<h3 className='est-blog-search-post-text-title'>24 Feb, 2021</h3>
									<h3 className='est-blog-search-post-text-subtitle'>
										His audiam deserunt in, eum
									</h3>
								</div>
							</div>
							<div className='est-blog-search-post-container'>
								<div className='est-blog-search-post-img-container'>
									<Image
										classImg={'est-blog-search-post-img'}
										image={SearchBlogPost}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>

								<div className='est-blog-search-post-text-container'>
									<h3 className='est-blog-search-post-text-title'>24 Feb, 2021</h3>
									<h3 className='est-blog-search-post-text-subtitle'>
										His audiam deserunt in, eum
									</h3>
								</div>
							</div>
							<div className='est-blog-search-post-container'>
								<div className='est-blog-search-post-img-container'>
									<Image
										classImg={'est-blog-search-post-img'}
										image={SearchBlogPost}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-search-post-text-container'>
									<h3 className='est-blog-search-post-text-title'>24 Feb, 2021</h3>
									<h3 className='est-blog-search-post-text-subtitle'>
										His audiam deserunt in, eum
									</h3>
								</div>
							</div>
							<div className='est-blog-search-post-container'>
								<div className='est-blog-search-post-img-container'>
									<Image
										classImg={'est-blog-search-post-img'}
										image={SearchBlogPost}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-search-post-text-container'>
									<h3 className='est-blog-search-post-text-title'>24 Feb, 2021</h3>
									<h3 className='est-blog-search-post-text-subtitle'>
										His audiam deserunt in, eum
									</h3>
								</div>
							</div>
						</div>

						<div className='est-blog-search-archive-global-container'>
							<h3 className='est-blog-search-title'>Archivos</h3>
							<hr className='est-blog-search-spacer'></hr>
							<div className='est-blog-search-archive-container'>
								<h3 className='est-blog-search-archive-title'>Diciembre, 2020</h3>
								<h3 className='est-blog-search-archive-title'>Febrero, 2021</h3>
								<h3 className='est-blog-search-archive-title'>Enero, 2021</h3>
								<h3 className='est-blog-search-archive-title'>Marzo, 2021</h3>
							</div>
						</div>

						<div className='est-blog-search-tags-global-container'>
							<h3 className='est-blog-search-title'>Tags</h3>
							<hr className='est-blog-search-spacer'></hr>

							<div className='est-blog-search-tags-button-container'>
								<Button className='est-blog-search-tags-button' htmlType={'submit'}>
									Camping
								</Button>
								<Button className='est-blog-search-tags-button' htmlType={'submit'}>
									Ecoturismo
								</Button>
								<Button className='est-blog-search-tags-button' htmlType={'submit'}>
									Puerto Rico
								</Button>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)
}
