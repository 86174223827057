/** @format */

import React from 'react'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import CardBlog from './components/CardBlog'

import { BLOG } from './data'

import './style.css'

export default function Blog() {
	return (
		<div className='est-blog-global-container'>
			<div className='est-blog-main-container'>
				<div className='est-blog-container'>
					<h2 className='est-blog-title-primary'>BLOG</h2>
					{/* 		<h2 className='est-blog-title-secondary'>NUESTRAS ÚLTIMAS ENTRADAS</h2> */}
					<h2 className='est-blog-title-secondary'>EN CONSTRUCCIÓN</h2>
				</div>
			</div>
			<div className='est-blog-map-main-container'>
				<Row className='est-blog-map-container'>
					{BLOG.map((item, index) => (
						<Col xs={24} sm={24} md={8} lg={8} xl={8} key={index}>
							<CardBlog item={item} />
						</Col>
					))}
				</Row>
			</div>
		</div>
	)
}
