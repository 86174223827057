/** @format */

import React from 'react'

import { Link } from 'react-router-dom'

import Image from '../../../../../../components/Common/Image'

import { PlusCircleFilled } from '@ant-design/icons'

import './style.css'

export default function CardBlog(props) {
	return (
		<div className='est-card-blog-global-container'>
			<div className='est-card-blog-main-container'>
				<Image
					classImg={'est-card-blog-img'}
					image={props.item.image}
					alt={props.item.name}
					title={props.item.name}
				/>
				<h3 className='card-blog-name'>{props.item.name}</h3>
				<h3 className='card-blog-subtitle'>{props.item.subtitle}</h3>
				<h3 className='card-blog-description'>{props.item.description}</h3>
				<Link to='/blog'>
					<h3 className='card-blog-link'>
						<PlusCircleFilled className='card-blog-link-icon' />
						{props.item.link}
					</h3>
				</Link>
			</div>
		</div>
	)
}
