/** @format */

import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import Loading from '../../components/Common/Loading'

import VerifyAccount from './services'

import { CheckCircleOutlined } from '@ant-design/icons'
import { CloseCircleOutlined } from '@ant-design/icons'

import './style.css'

export default function RegisterSuccess(props) {
	const [isVisible, setVisible] = useState(true)
	const [isSuccess, setSuccess] = useState(null)
	useEffect(() => {
		VerifyAccount(props.match.params.id).then((response) => {
			if (response) {
				setSuccess(response)
				if (!response) {
					setVisible(false)
				}
			} else {
				setVisible(false)
			}
		})
	}, [props.match.params.id])
	if (isVisible) {
		if (!isSuccess) {
			return <Loading />
		} else {
			return (
				<div className='est-register-success-global-container'>
					<div className='est-register-success-main-container'>
						<h3 className='est-register-success-icon'>
							<CheckCircleOutlined />
						</h3>
						<h3 className='est-register-success-title'>¡Felicidades!</h3>
						<h3 className='est-register-success-subtitle'>
							Registro procesado satisfactoriamente
						</h3>

						<div className='est-register-success-button-container'>
							<Link to='/' className='est-register-success-button'>
								Volver
							</Link>
						</div>
					</div>
				</div>
			)
		}
	} else {
		return (
			<div className='est-register-success-global-container'>
				<div className='est-register-success-main-container'>
					<h3 className='est-register-success-icon'>
						<CloseCircleOutlined />
					</h3>
					<h3 className='est-register-success-title'>¡Lo sentimos!</h3>
					<h3 className='est-register-success-subtitle'>
						Hubo un problema al procesar el registro
					</h3>

					<div className='est-register-success-button-container'>
						<Link to='/' className='est-register-success-button'>
							Volver
						</Link>
					</div>
				</div>
			</div>
		)
	}
}
