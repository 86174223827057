/** @format */

import React from 'react'
import { setGlobal } from 'reactn'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'

import Spacer from '../../../../../../components/Common/Spacer'

import Image from '../../../../../../components/Common/Image'

import ProfileImg from '../../../../../../img/detail/profile-example.png'

import ModalEditUser from '../ModalEditUser'

import './style.css'

export default function ProfileInfo(props) {
	return (
		<div className='est-profile-info-global-container'>
			<div className='est-profile-info-main-container'>
				<div className='est-profile-info-button-container'>
					<Button
						className='est-profile-info-button'
						type='primary'
						onClick={() =>
							setGlobal({
								useDrawer: true,
							})
						}>
						Menú
					</Button>
					<Spacer />
					<ModalEditUser isUser={props.isUserProfileInfo} />
				</div>
				<Row className='est-profile-info-container'>
					<Col
						xs={24}
						sm={8}
						md={8}
						lg={8}
						xl={8}
						className='est-profile-info-photo-global-container'>
						<div className='est-profile-info-photo-container'>
							{props.isUserProfileInfo.foto ? (
								<>
									<Image
										classImg={'est-profile-info-photo'}
										image={props.isUserProfileInfo.foto}
										alt={'Imagen Profile'}
										title={'Imagen Profile'}
									/>
								</>
							) : (
								<Image
									classImg={'est-profile-info-photo'}
									image={ProfileImg}
									alt={'Imagen Profile'}
									title={'Imagen Profile'}
								/>
							)}
							<h4 className='est-profile-info-photo-text'>Imagen actual de su perfil:</h4>
						</div>
					</Col>
					<Col xs={24} sm={16} md={16} lg={16} xl={16} className='est-profile-info-container'>
						<div className='est-profile-info-box'>
							<h3 className='est-profile-info-global-title'>Bienvenido:</h3>
							<ul className='est-profile-info-list'>
								<li>
									<h3 className='est-profile-info-title'>Usuario</h3>
								</li>
								<h4 className='est-profile-info-subtitle'>
									{props.isUserProfileInfo.nombre} {props.isUserProfileInfo.apellido}
								</h4>
								<li>
									<h3 className='est-profile-info-title'>Email:</h3>
								</li>
								<h4 className='est-profile-info-text'>{props.isUserProfileInfo.email}</h4>
								<li>
									<h3 className='est-profile-info-title'>Tipo de Inicio:</h3>
								</li>
								<h4 className='est-profile-info-subtitle'>
									{props.isUserProfileInfo.modo}
								</h4>
								<li>
									<h3 className='est-profile-info-title'>Telefono:</h3>
								</li>
								<h4 className='est-profile-info-text'>
									{props.isUserProfileInfo.telefono}
								</h4>
								<li>
									<h3 className='est-profile-info-title'>Fecha de Creacion:</h3>
								</li>
								<h4 className='est-profile-info-text'>
									{props.isUserProfileInfo.fecha_creacion}
								</h4>
								<li>
									<h3 className='est-profile-info-title'>Ultimo Login:</h3>
								</li>
								<h4 className='est-profile-info-text'>
									{props.isUserProfileInfo.ultimo_login}
								</h4>
							</ul>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)
}
