/** @format */

import React, { Component } from 'react'
import AwesomeSwiper from 'react-awesome-swiper'

import Image from '../Image'

import './style.css'

export default class CarouselGallery extends Component {
	state = {
		config: {
			spaceBetween: 0,
			loop: true,
			autoplay: {
				delay: 3000,
				stopOnLastSlide: false,
				disableOnInteraction: true,
			},
			preloadImages: false,
			lazy: true,
			speed: 500,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				1024: {
					slidesPerView: this.props.carouselItems[0],
					spaceBetween: 0,
				},
				768: {
					slidesPerView: this.props.carouselItems[1],
					spaceBetween: 10,
				},
				640: {
					slidesPerView: this.props.carouselItems[2],
					spaceBetween: 0,
				},
				320: {
					slidesPerView: this.props.carouselItems[3],
					spaceBetween: 0,
				},
				250: {
					slidesPerView: this.props.carouselItems[4],
					spaceBetween: 0,
				},
			},
		},
	}

	swiperRef = null
	render() {
		return (
			<>
				<AwesomeSwiper ref={(ref) => (this.swiperRef = ref)} config={this.state.config}>
					<div className='swiper-wrapper'>
						{this.props.banners.map(function (item, i) {
							return (
								<div className='swiper-slide' key={i}>
									<Image
										classImg={'est-banner-image'}
										image={item.image}
										//alt={item.tipo}
										//title={item.tipo}
									/>
								</div>
							)
						})}
					</div>
					<div className='swiper-button-prev ph-carousel-prev'></div>
					<div className='swiper-button-next ph-carousel-next'></div>
				</AwesomeSwiper>
			</>
		)
	}
}
