/** @format */

import React from 'react'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import { LeftSquareFilled, RightSquareFilled } from '@ant-design/icons'

import CarouselPublications from './components/CarouselPublications'

import './style.css'

export default function Publication(props) {
	return (
		<div className='est-publication-global-container'>
			<div className='est-publication-main-container'>
				<Row className='est-publication-container'>
					<Col span={24}>
						<h2 className='est-publication-title-primary'>PUBLICACIONES</h2>
					</Col>
					<Col span={24}>
						<h2 className='est-publication-title-secondary'>NUESTROS PROYECTOS</h2>
					</Col>
				</Row>
				<Row className='est-publication-arrow-container'>
					<Col span={12} className='est-publication-arrow '>
						<LeftSquareFilled />
					</Col>
					<Col span={12} className='est-publication-arrow'>
						<RightSquareFilled />
					</Col>
				</Row>
			</div>
			<div className='est-publication-map-main-container'>
				<div className='est-publication-map-main-container'>
					<CarouselPublications carouselItems={[4, 4, 3, 2, 1]} item={props.publication} />
				</div>
			</div>
		</div>
	)
}
