/** @format */

import React from 'react'

import { setGlobal } from 'reactn'

import Autocomplete from 'react-google-autocomplete'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import './style.css'

export default function Address() {
	const handleSelect = async (item) => {
		geocodeByAddress(item.formatted_address)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				const location = {
					geo_location: [latLng.lat, latLng.lng],
					complete_address: item.formatted_address,
				}
				setGlobal({
					SelectedAddress: location,
				})
			})
			.catch((error) => console.error('Error', error))
	}

	return (
		<>
			<Autocomplete
				className='est-ads-location-address-input'
				apiKey='AIzaSyCqYJiQ-ZQz86se0rF4_7sbFQ9UBq8Ehrg'
				style={{ width: '100%' }}
				onPlaceSelected={(item) => handleSelect(item)}
				options={{
					types: ['(regions)'],
					componentRestrictions: { country: 'pr' },
				}}
			/>
		</>
	)
}
