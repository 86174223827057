/** @format */

import React from 'react'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import { PlusCircleFilled } from '@ant-design/icons'

import Image from '../../../../components/Common/Image'

import AboutIconOne from '../../../../img/about-us/icono-01.png'
import AboutIconTwo from '../../../../img/about-us/icono-02.png'
import AboutIconThree from '../../../../img/about-us/icono-03.png'
import ImageAbout from '../../../../img/about-us/about-us-main-img.png'

import './style.css'

export default function AboutUs() {
	return (
		<div className='est-about-global-container'>
			<Row className='est-about-main-container'>
				<Col xs={16} sm={16} md={16} lg={16} xl={16} className='est-about-text-container'>
					<h3 className='est-about-title-one'>Sobre Nosotros</h3>
					<h2 className='est-about-title-two'>
						compartimos nuestra tierra y nuestra cultura con todos
					</h2>
					<p className='est-about-title-three'>
						PRÚJULA es una empresa puertorriqueña pionera en compartir nuestra tierra y
						nuestra cultura con amantes y protectores de la naturaleza de Puerto Rico y todas
						partes del mundo. Nuestra MISIÓN es enriquecer la experiencia turística de quienes
						nos visitan, contribuir a la divulgación sobre uso responsable de nuestro
						patrimonio natural y construido.
					</p>
					<div className='est-about-link'>
						<PlusCircleFilled />
						<p className='est-about-link-text'>Ver más</p>
					</div>
					<Row className='est-about-box-main-container'>
						<Col className='est-about-box-one-container' xs={8} sm={8} md={8} lg={8} xl={8}>
							<div className='est-about-box-one'>
								<Image
									classImg={'est-about-icon'}
									image={AboutIconOne}
									alt={'About Us Image'}
									title={'About Us Image'}
								/>
								<p className='est-about-box-text-one'>Cuidamos el medio ambiente</p>
							</div>
						</Col>
						<Col className='est-about-box-two-container' xs={8} sm={8} md={8} lg={8} xl={8}>
							<div className='est-about-box-two'>
								<Image
									classImg={'est-about-icon'}
									image={AboutIconTwo}
									alt={'About Us Image'}
									title={'About Us Image'}
								/>
								<p className='est-about-box-text-two'>Compartimos nuestra tierra</p>
							</div>
						</Col>
						<Col className='est-about-box-three-container' xs={8} sm={8} md={8} lg={8} xl={8}>
							<div className='est-about-box-three'>
								<Image
									classImg={'est-about-icon'}
									image={AboutIconThree}
									alt={'About Us Image'}
									title={'About Us Image'}
								/>
								<p className='est-about-box-text-three'>Fomentamos el ecoturismo</p>
							</div>
						</Col>
					</Row>
				</Col>
				<Col xs={8} sm={8} md={8} lg={8} xl={8} className='est-about-img-container'>
					<Image
						classImg={'est-about-image'}
						image={ImageAbout}
						alt={'About Us Image'}
						title={'About Us Image'}
					/>
				</Col>
			</Row>
		</div>
	)
}
