/** @format */

import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Layout from '../components/Layout/'

import Init from '../pages/home'
import Verify from '../pages/register-verify'
import Success from '../pages/register-success'
import AdsBasicInfo from '../pages/publication/create/Pages/basic-info'
import AdsLocation from '../pages/publication/create/Pages/location'
import AdsPhotos from '../pages/publication/create/Pages/photos'
import AdsAmenities from '../pages/publication/create/Pages/amenities'
import AdsSuccess from '../pages/publication/create/Pages/success'
import AdsError from '../pages/publication/create/Pages/error'
import AdsDetail from '../pages/publication/detail'
import Profile from '../pages/profile/pages/user-data'
import PaymentForm from '../pages/profile/pages/payment'
import Metrics from '../pages/profile/pages/data-metrics'
import UserPublications from '../pages/profile/pages/user-publications'
import UserReservations from '../pages/profile/pages/user-reservations'
import NotFound from '../pages/not-found'
import Blog from '../pages/blog'
import BlogDetail from '../pages/blog-detail'
import ReservationSuccess from '../pages/reservation-success'

import PrivateLogin from './components/PrivateLogin'
import PrivatePublicate from './components/PrivatePublicate'

import Test from '../pages/test'
import Search from '../pages/search'
import Categories from '../pages/categories'
import CategoriesID from '../pages/categories'

const Routers = () => (
	<BrowserRouter>
		<Layout>
			<Switch>
				<Route exact path='/' component={Init} />
				<Route exact path='/register-verify' component={Verify} />
				<Route exact path='/publication/detail/:id' component={AdsDetail} />
				<Route path='/auth/register-sucess/:id' component={Success} />
				<Route exact path='/test' component={Test} />
				<Route exact path='/search/:id' component={Search} />
				<Route exact path='/categories/:categories' component={Categories} />
				<Route exact path='/categories/:categories/:id' component={CategoriesID} />
				<Route exact path='/blog' component={Blog} />
				<Route exact path='/blog-detail' component={BlogDetail} />
				<Route exact path='/reservation-success' component={ReservationSuccess} />

				<PrivateLogin exact path='/profile/user-data' component={Profile} />
				<PrivateLogin exact path='/profile/user-publications' component={UserPublications} />
				<PrivateLogin exact path='/profile/user-reservations' component={UserReservations} />
				<PrivateLogin exact path='/profile/metrics' component={Metrics} />
				<Route exact path='/profile/payment/:id' component={PaymentForm} />

				<PrivatePublicate
					exact
					path='/publication/create/basic-info'
					component={AdsBasicInfo}
				/>
				<PrivatePublicate exact path='/publication/create/location' component={AdsLocation} />
				<PrivatePublicate exact path='/publication/create/photos' component={AdsPhotos} />
				<PrivatePublicate exact path='/publication/create/amenities' component={AdsAmenities} />
				<PrivatePublicate exact path='/publication/create/success' component={AdsSuccess} />
				<PrivatePublicate exact path='/publication/create/error' component={AdsError} />

				<Route path='*' component={NotFound} />
			</Switch>
		</Layout>
	</BrowserRouter>
)

export default Routers
