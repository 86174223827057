/** @format */

import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import { CloseCircleOutlined } from '@ant-design/icons'

import Spacer from '../../../../../components/Common/Spacer'

import './style.css'

export default function ErrorAd() {
	useEffect(() => {
		localStorage.removeItem('info')
		localStorage.removeItem('info-location')
		localStorage.removeItem('info-photos')
		localStorage.removeItem('success')
	}, [])
	return (
		<div className='est-ads-error-global-container'>
			<div className='est-ads-error-main-container'>
				<div className='est-ads-error-text-global-container'>
					<h2 className='est-ads-error-text-icon'>
						<CloseCircleOutlined />
					</h2>
					<h2 className='est-ads-error-text-title'>Lo sentimos!</h2>
					<h3 className='est-ads-error-text-subtitle'>El anuncio no pudo ser creado</h3>
					<h3 className='est-ads-error-text-subtitle'>
						Le aconsejamos volver a intentarlo nuevamente o comunicarse con nuestro personal
					</h3>
				</div>

				<Row>
					<Col span={24} className='est-ads-error-button-container '>
						<Link className='est-ads-error-button' to='/publication/create/basic-info'>
							Volver a intentarlo
						</Link>
						<Spacer />
						<Link className='est-ads-error-button' to='/'>
							Contactanos
						</Link>
						<Spacer />
						<Link className='est-ads-error-button' to='/'>
							Volver al Inicio
						</Link>
					</Col>
				</Row>
			</div>
		</div>
	)
}
