/** @format */

import axios from 'axios'

import notification from 'antd/lib/notification'

import { ENV_CORE } from '../../components/Common/Hooks/Variables/Enviroment'

const servicesHome = {
	async MainBanners() {
		let returnResponse
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/banner/all`,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data.bannerInfo
				} else {
					notification['warning']({
						message: 'Advertencia:',
						description: 'Error en Servicio: REACT_APP_SERVICE_CORE - banner/all',
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: 'Verifique su conexión a Internet',
				})
			})
		return returnResponse
	},
	async Categories() {
		let returnResponse
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/category/all`,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data.cateInfo
				} else {
					notification['warning']({
						message: 'Advertencia:',
						description: 'Error en Servicio: REACT_APP_SERVICE_CORE - banner/all',
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: 'Verifique su conexión a Internet',
				})
			})
		return returnResponse
	},
	async Ads() {
		let returnResponse
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/publication/all`,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data.adsInfo
				} else {
					notification['warning']({
						message: 'Advertencia:',
						description: 'Error en Servicio: REACT_APP_SERVICE_CORE - Ads/all',
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: 'Verifique su conexión a Internet',
				})
			})
		return returnResponse
	},
}
export default servicesHome
