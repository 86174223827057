/** @format */

import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import { CheckCircleOutlined } from '@ant-design/icons'

import Spacer from '../../../../../components/Common/Spacer'

import './style.css'

export default function SuccessAd() {
	const [isInfoSuccess] = useState(JSON.parse(localStorage.getItem('success')))
	useEffect(() => {
		localStorage.removeItem('info')
		localStorage.removeItem('info-location')
		localStorage.removeItem('info-photos')
		return () => {
			localStorage.removeItem('success')
		}
	}, [])
	return (
		<div className='est-ads-success-global-container'>
			<div className='est-ads-success-main-container'>
				<div className='est-ads-success-text-global-container'>
					<h2 className='est-ads-success-text-icon'>
						<CheckCircleOutlined />
					</h2>
					<h2 className='est-ads-success-text-title'>¡Felicidades!</h2>
					<h3 className='est-ads-success-text-subtitle'>
						Su anuncio con el id
						<span className='est-ads-success-text-id'>#{isInfoSuccess.adsInfo}</span> ha sido
						creado de manera exitosa
					</h3>
				</div>
				<Row>
					<Col span={24} className='est-ads-success-button-container'>
						<Link
							className='est-ads-success-button'
							to={`/publication/detail/${isInfoSuccess.adsInfo}`}>
							Ver Detalle
						</Link>
						<Spacer />
						<Link className='est-ads-success-button' to='/'>
							Volver al Inicio
						</Link>
					</Col>
				</Row>
			</div>
		</div>
	)
}
