/** @format */

import React, { useState } from 'react'

import Autocomplete from 'react-google-autocomplete'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import MapLeaft from '../../components/Common/MapLeaft'

import './style.css'

export default function Test() {
	const [isUserAddress, setUserAddress] = useState({
		geo_location: [18.4655394, -66.1057355],
		complete_address: 'San Juan, Puerto Rico',
	})

	const handleSelect = async (item) => {
		geocodeByAddress(item.formatted_address)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				const location = {
					geo_location: [latLng.lat, latLng.lng],
					complete_address: item.formatted_address,
				}
				setUserAddress(location)
			})
			.catch((error) => console.error('Error', error))
	}

	return (
		<>
			<Autocomplete
				apiKey='AIzaSyCqYJiQ-ZQz86se0rF4_7sbFQ9UBq8Ehrg'
				style={{ width: '90%' }}
				onPlaceSelected={(item) => handleSelect(item)}
				options={{
					types: ['(regions)'],
					componentRestrictions: { country: 'pr' },
				}}
			/>
			<br />
			<br />
			<br />
			<br />
			{isUserAddress && (
				<>
					<h4>Direccion actual seleccionada: {isUserAddress.complete_address}</h4>
					<div className='test-container'>
						<MapLeaft
							center={isUserAddress.geo_location}
							zoom={12}
							title={'User Current'}
							description={'Address.'}
						/>
					</div>
				</>
			)}
			<br />
			<br />
			<br />
			<br />
		</>
	)
}
