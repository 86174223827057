/** @format */

import React, { useState, useEffect } from 'react'
import { useGlobal, setGlobal } from 'reactn'
import { Link } from 'react-router-dom'

import moment from 'moment'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
import Collapse from 'antd/lib/collapse'
import Tabs from 'antd/lib/tabs'

import { StarFilled, CaretRightOutlined } from '@ant-design/icons'

import HeadDescription from '../../../../components/Common/HeadDescription'
import Loading from '../../../../components/Common/Loading'

import { GetUserInfo } from '../../../../components/Common/Hooks/Functions/GetUserInfo'

import MainMenu from '../../components/MainMenu'
import LoadingData from '../../components/LoadingData'

import servicesPublications from './services'

import './style.css'

export default function UserListPublications() {
	const [isUser] = useState(JSON.parse(localStorage.getItem('userSession')))
	const [isDataProfile] = useGlobal('DataProfile')
	const [isUpdateLoading, setUpdateLoading] = useState(false)
	const [isUserPublications, setUserPublications] = useState(null)
	const [isUserPublicationsLoading, setUserPublicationsLoading] = useState(true)
	const { Panel } = Collapse
	const { TabPane } = Tabs

	const handleEditPublication = () => {
		console.log('Editar!')
	}

	const handleUpdateReservation = async (item, data) => {
		setUpdateLoading(true)
		await servicesPublications.UpdatePublication(item, data).then((response) => {
			if (response) {
				servicesPublications.UserPublications(isUser.id).then((response) => {
					if (response) {
						setUserPublications(response)
					} else {
						setUserPublicationsLoading(false)
					}
				})
			}
		})
		setUpdateLoading(false)
	}

	const handleCancelationDate = (item, data) => {
		let days
		if (item) {
			let todayDate = new Date()
			todayDate =
				todayDate.getFullYear() +
				'-' +
				0 +
				(todayDate.getMonth() + 1) +
				'-' +
				todayDate.getDate()
			todayDate = moment(todayDate)
			let cancellationDate = moment(item)
			days =
				'*(' +
				cancellationDate.diff(todayDate, 'days') +
				')' +
				` ${
					data
						? 'días restantes para que el cliente realice el pago.'
						: 'días restantes para cauducar la reservacion.'
				}`
		}
		return days
	}

	useEffect(() => {
		servicesPublications.UserPublications(isUser.id).then((response) => {
			if (response) {
				setUserPublications(response)
			}
		})
		GetUserInfo(isUser.id).then((response) => {
			if (response) {
				setGlobal({
					DataProfile: response,
				})
			} else {
				setTimeout(() => {
					window.location.pathname = '/'
				}, 5000)
			}
		})
	}, [isUser.id])
	if (!isDataProfile) {
		return <Loading />
	} else {
		return (
			console.log('isUserPublications', isUserPublications),
			(
				<>
					<HeadDescription
						title={`Perfil de Usuario - ${isUser.nombre} - ${isUser.id}`}
						name={'description'}
						content={'Camping Site'}
					/>

					<div className='est-detail-profile-banner-global-container'>
						<Row className='est-detail-profile-banner-main-container'>
							<Col span={24} className='est-detail-profile-banner-title-container'>
								<h3 className='est-detail-profile-banner-title'>Publicaciones</h3>
							</Col>
							<Col className='est-detail-profile-banner-link-container'>
								<Link to='/' className='est-detail-profile-banner-link'>
									Home
								</Link>
								<h3 className='est-detail-profile-banner-slash'>/</h3>
								<Link to='/' className='est-detail-profile-banner-link'>
									Publicaciones
								</Link>
							</Col>
						</Row>
					</div>

					<div className='est-user-publications-global-container'>
						<div className='est-user-publications-main-container'>
							<div className='est-user-publications-main-title-container'>
								<h2 className='est-user-publications-main-title'>
									Información del usuario
								</h2>
							</div>

							<div className='est-profile-user-publications-global-container'>
								<div className='est-profile-user-publications-aside-container'>
									<MainMenu user={isDataProfile} />
								</div>
								<div className='est-profile-publications-list-global-container'>
									<div className='est-profile-publications-list-main-container'>
										<div className='est-profile-publications-list-header-global-container'>
											<div className='est-profile-publications-list-button-container'>
												<Button
													className='est-profile-publications-list-button'
													type='primary'
													onClick={() =>
														setGlobal({
															useDrawer: true,
														})
													}>
													Menú
												</Button>
											</div>
											<div className='est-profile-publications-list-title-button-container'>
												<h2 className='est-profile-publications-list-title'>
													Publicaciones:
												</h2>
												<Button
													className='est-profile-publications-list-title-button'
													onClick={() => handleEditPublication()}>
													Editar Publicación
												</Button>
											</div>
										</div>
										<div className='est-profile-publications-list-collapse-container'>
											{isUserPublicationsLoading ? (
												<>
													{isUserPublications ? (
														<Collapse
															accordion
															expandIconPosition={'right'}
															expandIcon={({ isActive }) => (
																<CaretRightOutlined rotate={isActive ? 90 : 0} />
															)}>
															{isUserPublications.map((item, index) => (
																<Panel
																	className='est-profile-publications-list-collapse-item-container'
																	header={
																		<Row>
																			<Col xs={24} sm={9} md={2} lg={3} xl={3}>
																				<h3 className='est-list-collapse-item-title-one'>
																					{item.id_anuncio}
																				</h3>
																			</Col>
																			<Col xs={24} sm={15} md={6} lg={6} xl={6}>
																				<h3 className='est-list-collapse-item-title-two'>
																					{item.title}
																				</h3>
																			</Col>
																			<Col xs={24} sm={9} md={4} lg={5} xl={5}>
																				<h3 className='est-list-collapse-item-title-three'>
																					${item.price}
																				</h3>
																			</Col>
																			<Col
																				xs={24}
																				sm={15}
																				md={12}
																				lg={10}
																				xl={10}>
																				{item.pending.length > 0 && (
																					<>
																						<h3 className='est-list-collapse-item-title-four'>
																							<span className='est-list-collapse-item-title-icon'>
																								<StarFilled />
																							</span>{' '}
																							¡Tienes reservaciones para
																							autorizar!
																						</h3>
																					</>
																				)}
																			</Col>
																		</Row>
																	}
																	key={index}>
																	<Row>
																		<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																			<h4 className='est-list-collapse-item-content'>
																				<span className='est-list-collapse-item-span-title'>
																					Titulo:{' '}
																				</span>
																				{item.title}
																			</h4>
																		</Col>
																		<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																			<h4 className='est-list-collapse-item-content'>
																				<span className='est-list-collapse-item-span-title'>
																					Descuento:{' '}
																				</span>
																				${item.discount_amount}
																			</h4>
																		</Col>
																		<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																			<h4 className='est-list-collapse-item-content'>
																				<span className='est-list-collapse-item-span-title'>
																					Categoria:{' '}
																				</span>
																				{item.nombre_categoria}(
																				{item.id_category})
																			</h4>
																		</Col>
																		<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																			<h4 className='est-list-collapse-item-content'>
																				<span className='est-list-collapse-item-span-title'>
																					Precio:{' '}
																				</span>
																				${item.price}
																			</h4>
																		</Col>
																		<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																			<h4 className='est-list-collapse-item-content'>
																				<span className='est-list-collapse-item-span-title'>
																					Precio Oferta:{' '}
																				</span>
																				${item.price_offer}
																			</h4>
																		</Col>
																		<Col xs={24} sm={12} md={8} lg={8} xl={8}>
																			<h4 className='est-list-collapse-item-content'>
																				<span className='est-list-collapse-item-span-title'>
																					Personas:{' '}
																				</span>
																				{item.people}
																			</h4>
																		</Col>
																	</Row>

																	<div className='est-user-publications-list-tab-main-container'>
																		<h3 className='est-user-publications-list-tab-main-title'>
																			Estado de las reservaciones:
																		</h3>
																		{item.pending.length > 1 && (
																			<h3 className='est-list-collapse-item-span-subtitle'>
																				( Pendientes {item.pending.length} )
																			</h3>
																		)}
																	</div>

																	<div className='est-user-publications-list-reservations-global-container'>
																		<Tabs defaultActiveKey='1'>
																			<TabPane tab='Pendientes' key='1'>
																				{item.pending.length > 0 ? (
																					<>
																						{item.pending.map(
																							(element, i) => (
																								<div
																									key={i}
																									className='est-user-publications-list-reservations-main-container'>
																									<Row>
																										<Col
																											xs={24}
																											sm={14}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-reservations-list-main-title'>
																												<span className='est-user-reservations-list-main-span-title'>
																													ID del
																													anuncio:{' '}
																												</span>
																												{
																													element.id_anuncio
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-reservations-list-main-title'>
																												<span className='est-user-reservations-list-main-span-title'>
																													Título:{' '}
																												</span>
																												{element.title}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={10}
																											lg={10}
																											xl={10}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Nombre del
																													solicitante:{' '}
																												</span>
																												{element.nombre}{' '}
																												{
																													element.apellido
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Dias
																													Reservados:{' '}
																												</span>
																												{
																													element.cantidad_dias
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-reservations-list-main-title'>
																												<span className='est-user-reservations-list-main-span-title'>
																													Personas:{' '}
																												</span>
																												{
																													element.cantidad_personas
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={10}
																											lg={10}
																											xl={10}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Fecha Inicio:{' '}
																												</span>
																												{
																													element.fecha_desde
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Fecha Fin:{' '}
																												</span>
																												{
																													element.fecha_hasta
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Subtotal:{' '}
																												</span>
																												$
																												{element.precio}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={10}
																											lg={10}
																											xl={10}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Impuestos:{' '}
																												</span>
																												$
																												{
																													element.impuesto
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Total:{' '}
																												</span>
																												${element.total}
																											</h4>
																										</Col>
																									</Row>
																									<Col span={24}>
																										<div className='est-user-publications-date-left'>
																											{handleCancelationDate(
																												element.fecha_vencimiento
																											)}
																										</div>
																									</Col>
																									<Col
																										span={24}
																										className='est-user-publications-list-reservations-button-container'>
																										<Button
																											className='est-user-publications-list-reservations-button'
																											loading={
																												isUpdateLoading
																											}
																											onClick={() =>
																												handleUpdateReservation(
																													'1',
																													element.id
																												)
																											}>
																											Aprobar
																										</Button>
																										<Button
																											className='est-user-publications-list-reservations-button'
																											loading={
																												isUpdateLoading
																											}
																											onClick={() =>
																												handleUpdateReservation(
																													'2',
																													element.id
																												)
																											}>
																											Cancelar
																										</Button>
																									</Col>
																								</div>
																							)
																						)}
																					</>
																				) : (
																					<h3 className='est-user-publications-list-tab-main-subtitle'>
																						No se encontraron registros
																					</h3>
																				)}
																			</TabPane>
																			<TabPane tab='Aprobadas' key='2'>
																				{item.approved.length > 0 ? (
																					<>
																						{item.approved.map(
																							(element, i) => (
																								<div
																									key={i}
																									className='est-user-publications-list-reservations-main-container'>
																									<Row>
																										<Col
																											xs={24}
																											sm={14}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-reservations-list-main-title'>
																												<span className='est-user-reservations-list-main-span-title'>
																													ID del
																													anuncio:{' '}
																												</span>
																												{
																													element.id_anuncio
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-reservations-list-main-title'>
																												<span className='est-user-reservations-list-main-span-title'>
																													Título:{' '}
																												</span>
																												{element.title}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={10}
																											lg={10}
																											xl={10}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Nombre del
																													solicitante:{' '}
																												</span>
																												{element.nombre}{' '}
																												{
																													element.apellido
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Dias
																													Reservados:{' '}
																												</span>
																												{
																													element.cantidad_dias
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-reservations-list-main-title'>
																												<span className='est-user-reservations-list-main-span-title'>
																													Personas:{' '}
																												</span>
																												{
																													element.cantidad_personas
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={10}
																											lg={10}
																											xl={10}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Fecha Inicio:{' '}
																												</span>
																												{
																													element.fecha_desde
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Fecha Fin:{' '}
																												</span>
																												{
																													element.fecha_hasta
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Subtotal:{' '}
																												</span>{' '}
																												$
																												{element.precio}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={10}
																											lg={10}
																											xl={10}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Impuestos:{' '}
																												</span>{' '}
																												$
																												{
																													element.impuesto
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Total:{' '}
																												</span>{' '}
																												${element.total}
																											</h4>
																										</Col>
																										<Col span={24}>
																											<div className='est-user-publications-date-left'>
																												{handleCancelationDate(
																													element.fecha_vencimiento,
																													true
																												)}
																											</div>
																										</Col>
																									</Row>
																								</div>
																							)
																						)}
																					</>
																				) : (
																					<h3 className='est-user-publications-list-tab-main-subtitle'>
																						No se encontraron registros
																					</h3>
																				)}
																			</TabPane>
																			<TabPane tab='Canceladas' key='3'>
																				{item.canceled.length > 0 ? (
																					<>
																						{item.canceled.map(
																							(element, i) => (
																								<div
																									key={i}
																									className='est-user-publications-list-reservations-main-container'>
																									<Row>
																										<Col
																											xs={24}
																											sm={14}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-reservations-list-main-title'>
																												<span className='est-user-reservations-list-main-span-title'>
																													ID del
																													anuncio:{' '}
																												</span>
																												{
																													element.id_anuncio
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-reservations-list-main-title'>
																												<span className='est-user-reservations-list-main-span-title'>
																													Título:{' '}
																												</span>
																												{element.title}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={10}
																											lg={10}
																											xl={10}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Nombre del
																													solicitante:{' '}
																												</span>
																												{element.nombre}{' '}
																												{
																													element.apellido
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Dias
																													Reservados:{' '}
																												</span>
																												{
																													element.cantidad_dias
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-reservations-list-main-title'>
																												<span className='est-user-reservations-list-main-span-title'>
																													Personas:{' '}
																												</span>
																												{
																													element.cantidad_personas
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={10}
																											lg={10}
																											xl={10}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Fecha Inicio:{' '}
																												</span>
																												{
																													element.fecha_desde
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Fecha Fin:{' '}
																												</span>
																												{
																													element.fecha_hasta
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Subtotal:{' '}
																												</span>
																												$
																												{element.precio}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={14}
																											md={10}
																											lg={10}
																											xl={10}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Impuestos:{' '}
																												</span>{' '}
																												$
																												{
																													element.impuesto
																												}
																											</h4>
																										</Col>
																										<Col
																											xs={24}
																											sm={10}
																											md={7}
																											lg={7}
																											xl={7}>
																											<h4 className='est-user-publications-list-reservations-main-title'>
																												<span className='est-user-publications-list-reservations-main-span-title'>
																													Total:{' '}
																												</span>{' '}
																												${element.total}
																											</h4>
																										</Col>
																									</Row>
																								</div>
																							)
																						)}
																					</>
																				) : (
																					<h3 className='est-user-publications-list-tab-main-subtitle'>
																						No se encontraron registros
																					</h3>
																				)}
																			</TabPane>
																		</Tabs>
																	</div>
																</Panel>
															))}
														</Collapse>
													) : (
														<LoadingData />
													)}
												</>
											) : (
												<h2 className='est-user-profile-publications-list-main-title'>
													No se encontraron publicaciones
												</h2>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)
		)
	}
}
