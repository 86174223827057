/** @format */

import React, { useState } from 'react'

import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'

import Spacer from '../../../../../src/components/Common/Spacer'

import InputCustom from '../../../../components/Common/Inputs/Normal'

import './style.css'

export default function ContactUs() {
	const { Option } = Select
	const { TextArea } = Input
	const [isContactLoading, setContactLoading] = useState(false)
	const handleContact = async () => {
		setContactLoading(true)
		setContactLoading(false)
	}
	return (
		<div className='est-contact-us-global-container'>
			<div className='est-contact-us-main-container'>
				<div className='est-contact-us-container'>
					<h2 className='est-contact-us-title-primary'>CONTÁCTANOS</h2>
					<h2 className='est-contact-us-title-secondary'>¿CÓMO PODEMOS AYUDARTE?</h2>
				</div>
				<Row className='est-contact-us-form-container'>
					<Col className='est-main-contact-input-container'>
						<Form
							className='est-main-contact-global-container'
							name='form_contact'
							onFinish={handleContact}>
							<Row>
								<Col
									xs={24}
									sm={24}
									md={8}
									lg={8}
									xl={8}
									className='est-main-contact-input-container'>
									<InputCustom
										className={'est-home-contact-field-input'}
										inputName={'name'}
										inputNameLabel={'Nombre'}
										inputNameRule={true}
										inputNameMessage={'Ingrese su Nombre'}
										inputNameType={'text'}
										inputNameIcon={''}
										inputNameRules={'rulesRequired'}
									/>
								</Col>
								<Spacer />
								<Col
									xs={24}
									sm={24}
									md={8}
									lg={8}
									xl={8}
									className='est-main-contact-input-container-middle'>
									<InputCustom
										className={'est-home-contact-field-input'}
										inputName={'email'}
										inputNameLabel={'Correo Electrónico'}
										inputNameRule={true}
										inputNameMessage={'Ingrese un Correo Electrónico'}
										inputNameType={'text'}
										inputNameIcon={''}
										inputNameRules={'rulesRequired'}
									/>
								</Col>
								<Spacer />
								<Col
									xs={24}
									sm={24}
									md={8}
									lg={8}
									xl={8}
									className='est-main-contact-input-container-right'>
									<Select
										className='est-home-contact-field-select'
										placeholder='Tipo de Solicitud'>
										<Option value='1'>1</Option>
										<Option value='2'>2</Option>
										<Option value='3'>3</Option>
									</Select>
								</Col>
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={24}
									xl={24}
									className='est-main-contact-input-container'>
									<Form.Item name={'message'}>
										<TextArea
											className='est-home-contact-field-input'
											size='large'
											type={'text'}
											placeholder={'Mensaje'}
											rows={4}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row className='est-contact-us-button-container'>
								<Col
									xs={24}
									sm={24}
									md={24}
									lg={24}
									xl={24}
									className='est-main-contact-input-button-container'>
									<Button
										className='est-main-form-main-button'
										htmlType={'submit'}
										loading={isContactLoading}>
										ENVIAR
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</div>
		</div>
	)
}
