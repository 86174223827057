/** @format */

import React, { useState, useEffect } from 'react'

import { useGlobal } from 'reactn'

import { Link } from 'react-router-dom'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import notification from 'antd/lib/notification'

import Spacer from '../../../../../components/Common/Spacer'

import { rulesValidation } from '../../../../../components/Common/Inputs/Normal/rules'

import InputMask from '../../../../../components/Common/Inputs/InputMask'
import { rulesValidationMask } from '../../../../../components/Common/Inputs/InputMask/rules'

import MapLeaft from '../../../../../components/Common/MapLeaft'

import Address from './components/Address'

import './style.css'

export default function AdsLocation() {
	const [isBasicInfo] = useState(JSON.parse(localStorage.getItem('info')))
	const [isUserAddress, SetUserAddress] = useGlobal('SelectedAddress')
	const { TextArea } = Input

	const handleLocationInfo = async (item) => {
		if (isUserAddress) {
			if (isUserAddress.default) {
				notification['warning']({
					message: 'Advertencia:',
					description: 'Debe elegir una dirección para su propiedad.',
				})
				return
			}
		}
		let info = JSON.parse(localStorage.getItem('info'))
		let splitAddress = isUserAddress.complete_address.split(',').reverse()
		let regAddress = {
			completeAddress: isUserAddress.complete_address,
			country: splitAddress[0],
			countryCode: 'pr',
			county: '',
			city: splitAddress[1],
			municipality: '',
			state: splitAddress[2] ? splitAddress[2] : '',
			lat: isUserAddress.geo_location[0],
			lng: isUserAddress.geo_location[1],
		}
		info.regAddress = regAddress
		info.regAddressDescription = item.regAddressDescription
		info.regPhone = item.regPhone.replace(/[+()/\s/]/g, '')
		info.regImageGallery = []
		localStorage.setItem('info-location', JSON.stringify(info))
		window.location.pathname = 'publication/create/photos'
	}
	useEffect(() => {
		SetUserAddress({
			geo_location: [18.4655394, -66.1057355],
			complete_address: 'San Juan, Puerto Rico',
			default: true,
		})
		if (!isBasicInfo) {
			window.location.pathname = '/'
		}
	}, [isBasicInfo, SetUserAddress])

	return (
		<div className='est-ads-location-info-global-container'>
			<h2 className='est-ads-location-info-global-title'>
				Formulario de publicación de Arrendador
			</h2>
			<h3 className='est-ads-location-info-global-subtitle'>Ubicación:</h3>
			<Form name='location' onFinish={handleLocationInfo}>
				<Row className='est-ads-location-info-main-container'>
					<Col
						xs={24}
						sm={24}
						md={12}
						lg={12}
						xl={16}
						className='est-ads-location-info-input-container'>
						<Row>
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<h4 className='est-ads-location-info-main-title'>
									Dirección (Información adicional):
								</h4>
								<Address />
								<br />
								<br />
								<h4 className='est-ads-location-info-main-title'>
									Información adicional (Dirección):
								</h4>
								<Form.Item
									name={'regAddressDescription'}
									rules={rulesValidation.rulesRequired}>
									<TextArea
										className='est-ads-location-field-input'
										size='large'
										type={'text'}
										placeholder={'Dirección'}
										rows={4}
									/>
								</Form.Item>
								<h4 className='est-ads-location-info-main-title'>Teléfono de contacto:</h4>
								<Form.Item name={'regPhone'} rules={rulesValidationMask.rulesPhone}>
									<InputMask
										maskstyle={'est-ads-location-input-main-phone'}
										mask='+(1) 999 999 9999'
										placeholder='+(1) 999 999 9999'
									/>
								</Form.Item>
								<h4 className='est-ads-location-info-main-title'>Direción Elegida:</h4>
								{isUserAddress && (
									<>
										{isUserAddress.default ? (
											<h3 className='est-ads-location-info-main-subtitle'>
												Seleccione una direccion ingresando una en el buscador.
											</h3>
										) : (
											<h3>{isUserAddress.complete_address}</h3>
										)}
									</>
								)}
							</Col>
						</Row>
					</Col>
					<hr className='est-ads-location-info-spacer'></hr>
					<Col
						xs={24}
						sm={24}
						md={12}
						lg={12}
						xl={8}
						className='est-ads-location-map-container'>
						{isUserAddress && (
							<>
								<h4 className='est-ads-location-map-title'>Direccion seleccionada: </h4>
								{isUserAddress && (
									<>
										{isUserAddress.default ? (
											<h4 className='est-ads-location-map-subtitle'>No hay ninguna.</h4>
										) : (
											<h4 className='est-ads-location-map-subtitle'>
												{isUserAddress.complete_address}
											</h4>
										)}
									</>
								)}
								<div className='est-address-box-container'>
									<MapLeaft
										center={isUserAddress.geo_location}
										zoom={12}
										title={'User Current'}
										description={'Address.'}
									/>
								</div>
							</>
						)}
					</Col>
				</Row>
				<Row>
					<Col span={24} className='est-ads-location-info-button-container'>
						<Link
							className='est-ads-location-info-button'
							to='/publication/create/basic-info'>
							Anterior
						</Link>
						<Spacer />
						<Button className='est-ads-location-info-button' htmlType={'submit'}>
							Siguiente
						</Button>
					</Col>
				</Row>
			</Form>
		</div>
	)
}
