/** @format */

import React from 'react'

import { MailOutlined } from '@ant-design/icons'

import './style.css'

export default function RegisterVerify() {
	return (
		<div className='est-register-verify-global-container'>
			<div className='est-register-verify-main-container'>
				<h3 className='est-register-verify-icon'>
					<MailOutlined />
				</h3>
				<h3 className='est-register-verify-title'>¡Felicidades!</h3>
				<h3 className='est-register-verify-subtitle'>
					Verifique su correo electrónico para confirmar la cuenta.
				</h3>
			</div>
		</div>
	)
}
