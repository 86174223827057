/** @format */

import React from 'react'
import { Link } from 'react-router-dom'

import MainNavigation from '../../components/Common/MainNavigation'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Spacer from '../../components/Common/Spacer'

import Button from 'antd/lib/button'
import Input from 'antd/lib/input'

import {
	FolderOutlined,
	WechatOutlined,
	HeartOutlined,
	SearchOutlined,
	LeftOutlined,
	RightOutlined,
} from '@ant-design/icons'

import Image from '../../components/Common/Image'
import BlogMainImage from '../../../src/img/blog/youngcouple-blog-simple.png'
import SearchBlogPost from '../../../src/img/blog/4-people-camping.png'

import './style.css'

export default function BlogDetail() {
	return (
		<div className='est-blog-menu-global-container'>
			<MainNavigation
				title={'Menú'}
				linkPrevTitle={'Home'}
				linkNextTitle={'Blog'}
				linkPrev={'/'}
				linkNext={'/'}
			/>
			<div className='est-blog-menu-main-container'>
				<Row>
					<Col span={18} className='est-blog-menu-left-global-container'>
						<div className='est-blog-menu-img-title-container'>
							<div className='est-blog-menu-img-container'>
								<Image
									classImg={'est-blog-main-img'}
									image={BlogMainImage}
									alt={'Profile photo'}
									title={'Profile photo'}
								/>

								<div className='est-blog-menu-img-date'>
									<h3 className='est-blog-menu-img-date-title'>26</h3>
									<h3 className='est-blog-menu-img-date-subtitle'>04</h3>
								</div>
							</div>

							<div className='est-blog-menu-content-container'>
								<h3 className='est-blog-menu-title'>
									Fusce porta tincidunt neque, vel egestas nunc tristique vitae. Sed.
								</h3>
								<p className='est-blog-menu-text'>
									Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
									nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
									volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
									ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
									Duis autem vel eum iriure dolor in hendrerit in vulputate.
								</p>
								<hr className='est-blog-search-spacer'></hr>
								<div className='est-blog-menu-subtitle-container'>
									<h4 className='est-blog-menu-category-text'>
										<FolderOutlined /> Categoría
									</h4>
									<h4 className='est-blog-menu-category-icon'>
										<WechatOutlined /> 12
									</h4>
									<h4 className='est-blog-menu-category-icon'>
										<HeartOutlined /> 6
									</h4>
								</div>
							</div>
						</div>
						<div className='est-blog-menu-img-title-container'>
							<div className='est-blog-menu-img-container'>
								<Image
									classImg={'est-blog-main-img'}
									image={BlogMainImage}
									alt={'Profile photo'}
									title={'Profile photo'}
								/>

								<div className='est-blog-menu-img-date'>
									<h3 className='est-blog-menu-img-date-title'>26</h3>
									<h3 className='est-blog-menu-img-date-subtitle'>04</h3>
								</div>
							</div>

							<div className='est-blog-menu-content-container'>
								<h3 className='est-blog-menu-title'>
									Fusce porta tincidunt neque, vel egestas nunc tristique vitae. Sed.
								</h3>
								<p className='est-blog-menu-text'>
									Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
									nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
									volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
									ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
									Duis autem vel eum iriure dolor in hendrerit in vulputate.
								</p>
								<hr className='est-blog-search-spacer'></hr>
								<div className='est-blog-menu-subtitle-container'>
									<h4 className='est-blog-menu-category-text'>
										<FolderOutlined /> Categoría
									</h4>
									<h4 className='est-blog-menu-category-icon'>
										<WechatOutlined /> 12
									</h4>
									<h4 className='est-blog-menu-category-icon'>
										<HeartOutlined /> 6
									</h4>
								</div>
							</div>
						</div>
						<div className='est-blog-menu-img-title-container'>
							<div className='est-blog-menu-img-container'>
								<Image
									classImg={'est-blog-main-img'}
									image={BlogMainImage}
									alt={'Profile photo'}
									title={'Profile photo'}
								/>

								<div className='est-blog-menu-img-date'>
									<h3 className='est-blog-menu-img-date-title'>26</h3>
									<h3 className='est-blog-menu-img-date-subtitle'>04</h3>
								</div>
							</div>

							<div className='est-blog-menu-content-container'>
								<h3 className='est-blog-menu-title'>
									Fusce porta tincidunt neque, vel egestas nunc tristique vitae. Sed.
								</h3>
								<p className='est-blog-menu-text'>
									Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
									nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
									volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
									ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
									Duis autem vel eum iriure dolor in hendrerit in vulputate.
								</p>
								<hr className='est-blog-search-spacer'></hr>
								<div className='est-blog-menu-subtitle-container'>
									<h4 className='est-blog-menu-category-text'>
										<FolderOutlined /> Categoría
									</h4>
									<h4 className='est-blog-menu-category-icon'>
										<WechatOutlined /> 12
									</h4>
									<h4 className='est-blog-menu-category-icon'>
										<HeartOutlined /> 6
									</h4>
								</div>
							</div>
						</div>
						<div className='est-blog-menu-img-title-container'>
							<div className='est-blog-menu-img-container'>
								<Image
									classImg={'est-blog-main-img'}
									image={BlogMainImage}
									alt={'Profile photo'}
									title={'Profile photo'}
								/>

								<div className='est-blog-menu-img-date'>
									<h3 className='est-blog-menu-img-date-title'>26</h3>
									<h3 className='est-blog-menu-img-date-subtitle'>04</h3>
								</div>
							</div>

							<div className='est-blog-menu-content-container'>
								<h3 className='est-blog-menu-title'>
									Fusce porta tincidunt neque, vel egestas nunc tristique vitae. Sed.
								</h3>
								<p className='est-blog-menu-text'>
									Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
									nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
									volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
									ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
									Duis autem vel eum iriure dolor in hendrerit in vulputate.
								</p>
								<hr className='est-blog-search-spacer'></hr>
								<div className='est-blog-menu-subtitle-container'>
									<h4 className='est-blog-menu-category-text'>
										<FolderOutlined /> Categoría
									</h4>
									<h4 className='est-blog-menu-category-icon'>
										<WechatOutlined /> 12
									</h4>
									<h4 className='est-blog-menu-category-icon'>
										<HeartOutlined /> 6
									</h4>
								</div>
							</div>
						</div>

						<div className='est-blog-menu-button-container'>
							<Link className='est-blog-menu-button' to='/blog-detail'>
								<LeftOutlined className='est-blog-menu-button-icon' /> Post antiguos
							</Link>
							<Spacer />
							<Link className='est-blog-menu-button' to='/blog-detail'>
								Post recientes <RightOutlined className='est-blog-menu-button-icon' />
							</Link>
						</div>
					</Col>

					<Col span={6} className='est-blog-menu-right-global-container'>
						<div className='est-blog-search-title-input-container'>
							<h3 className='est-blog-search-title'>Buscar</h3>
							<hr className='est-blog-search-spacer'></hr>
							<div className='est-blog-search-input-container'>
								<Input
									size='large'
									placeholder='Ingrese una palabra clave'
									prefix={<SearchOutlined className='est-blog-search-input-icon' />}
								/>
							</div>
						</div>
						<div className='est-blog-search-categories-global-container'>
							<h3 className='est-blog-search-title'>Categorias</h3>
							<hr className='est-blog-search-spacer'></hr>
							<div className='est-blog-search-categories-container'>
								<h3 className='est-blog-search-categories-title'>Gampling</h3>
								<h3 className='est-blog-search-categories-title'>Camper</h3>
								<h3 className='est-blog-search-categories-title'>Mochila</h3>
								<h3 className='est-blog-search-categories-title'>Baúl</h3>
							</div>
						</div>

						<div className='est-blog-search-post-global-container'>
							<h3 className='est-blog-search-title'>Post recientes</h3>
							<hr className='est-blog-search-spacer'></hr>

							<div className='est-blog-search-post-container'>
								<div className='est-blog-search-post-img-container'>
									<Image
										classImg={'est-blog-search-post-img'}
										image={SearchBlogPost}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-search-post-text-container'>
									<h3 className='est-blog-search-post-text-title'>24 Feb, 2021</h3>
									<h3 className='est-blog-search-post-text-subtitle'>
										His audiam deserunt in, eum
									</h3>
								</div>
							</div>
							<div className='est-blog-search-post-container'>
								<div className='est-blog-search-post-img-container'>
									<Image
										classImg={'est-blog-search-post-img'}
										image={SearchBlogPost}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>

								<div className='est-blog-search-post-text-container'>
									<h3 className='est-blog-search-post-text-title'>24 Feb, 2021</h3>
									<h3 className='est-blog-search-post-text-subtitle'>
										His audiam deserunt in, eum
									</h3>
								</div>
							</div>
							<div className='est-blog-search-post-container'>
								<div className='est-blog-search-post-img-container'>
									<Image
										classImg={'est-blog-search-post-img'}
										image={SearchBlogPost}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-search-post-text-container'>
									<h3 className='est-blog-search-post-text-title'>24 Feb, 2021</h3>
									<h3 className='est-blog-search-post-text-subtitle'>
										His audiam deserunt in, eum
									</h3>
								</div>
							</div>
							<div className='est-blog-search-post-container'>
								<div className='est-blog-search-post-img-container'>
									<Image
										classImg={'est-blog-search-post-img'}
										image={SearchBlogPost}
										alt={'Profile photo'}
										title={'Profile photo'}
									/>
								</div>
								<div className='est-blog-search-post-text-container'>
									<h3 className='est-blog-search-post-text-title'>24 Feb, 2021</h3>
									<h3 className='est-blog-search-post-text-subtitle'>
										His audiam deserunt in, eum
									</h3>
								</div>
							</div>
						</div>

						<div className='est-blog-search-archive-global-container'>
							<h3 className='est-blog-search-title'>Archivos</h3>
							<hr className='est-blog-search-spacer'></hr>
							<div className='est-blog-search-archive-container'>
								<h3 className='est-blog-search-archive-title'>Diciembre, 2020</h3>
								<h3 className='est-blog-search-archive-title'>Febrero, 2021</h3>
								<h3 className='est-blog-search-archive-title'>Enero, 2021</h3>
								<h3 className='est-blog-search-archive-title'>Marzo, 2021</h3>
							</div>
						</div>

						<div className='est-blog-search-tags-global-container'>
							<h3 className='est-blog-search-title'>Tags</h3>
							<hr className='est-blog-search-spacer'></hr>

							<div className='est-blog-search-tags-button-container'>
								<Button className='est-blog-search-tags-button' htmlType={'submit'}>
									Camping
								</Button>
								<Button className='est-blog-search-tags-button' htmlType={'submit'}>
									Ecoturismo
								</Button>
								<Button className='est-blog-search-tags-button' htmlType={'submit'}>
									Puerto Rico
								</Button>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)
}
