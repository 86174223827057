/** @format */

import React, { useState, useEffect } from 'react'

import { setGlobal } from 'reactn'

import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'

import HeadDescription from '../../../../components/Common/HeadDescription'
import Loading from '../../../../components/Common/Loading'
import InputCustom from '../../../../components/Common/Inputs/Normal'
import InputMask from '../../../../components/Common/Inputs/InputMask'

import { rulesValidationMask } from '../../../../components/Common/Inputs/InputMask/rules'

import { CheckReservation } from '../../../../components/Common/Hooks/Functions/CheckReservation'

import SendPayment from './services'

import './style.css'

export default function PaymentForm(props) {
	const [date] = useState(new Date())
	const [isUserSession] = useState(JSON.parse(localStorage.getItem('userSession')))
	const [isValidData, setValidData] = useState(null)
	const [isPaymentResponse, setPaymentResponse] = useState(true)
	const [isButtonLoading, setButtonLoading] = useState(false)
	const { Option } = Select

	const handleProcessPayment = (item) => {
		setButtonLoading(true)
		let info = {
			credentials: {
				Company: 'acceptatest',
				Username: 'AshbelRoldanVT',
				Password: '123456Ab',
			},
			data: item,
		}
		info.data.CardPresent = 'Y'
		info.data.CustomerPresentIndicator = '0'
		info.data.TerminalCapabilities = '3'
		info.data.EntryMode = '1'
		console.log('INFO!!!!', info)
		SendPayment(info).then((response) => {
			setButtonLoading(false)
			if (response) {
				console.log('SUCCESS!!!!', response)
			}
		})
	}

	useEffect(() => {
		CheckReservation(props.match.params.id).then((response) => {
			if (response) {
				if (!isUserSession) {
					let session = {
						nombre: response.nombre,
						apellido: response.apellido,
						id: response.id_user,
						modo: 'directo',
						email: response.email,
					}
					localStorage.setItem('userSession', JSON.stringify(session))
					setGlobal(() => ({
						userEmail: `${session.nombre} ${session.apellido}`,
						userData: session,
					}))
				}
				setValidData(response)
			} else {
				setPaymentResponse(false)
			}
		})
	}, [props.match.params.id, isUserSession])
	if (isPaymentResponse) {
		if (!isValidData) {
			return <Loading />
		} else {
			return (
				<>
					<HeadDescription
						title={`Formulario de Pago - Reservaciones`}
						name={'description'}
						content={'Camping Site'}
					/>
					<div className='est-profile-payment-global-container'>
						<h3 className='est-profile-payment-global-title'>Formulario de Pago:</h3>
						<Form
							name='reservation_payment'
							onFinish={handleProcessPayment}
							initialValues={{
								InvoiceNumber: isValidData.id,
								AmountOfTransaction: isValidData.total,
							}}>
							<h3 className='est-profile-payment-main-title'>Información Requerida:</h3>
							<Row>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>
										Numero de reservación:
									</h5>
									<InputCustom
										className={''}
										inputName={'InvoiceNumber'}
										inputNameLabel={'Invoice Number'}
										inputNameMessage={'Campo obligatorio'}
										inputNameType={'text'}
										inputNameRule={false}
										inputNameIcon={''}
										inputNameRules={'rulesRequired'}
										disabled={true}
									/>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>Monto:</h5>
									<InputCustom
										className={''}
										inputName={'AmountOfTransaction'}
										inputNameLabel={'Monto'}
										inputNameMessage={'Campo obligatorio'}
										inputNameType={'text'}
										inputNameRule={true}
										inputNameIcon={''}
										inputNameRules={'rulesRequired'}
										//disabled={true}
									/>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>Tipo de Tarjeta:</h5>
									<Form.Item
										name='CardType'
										rules={[
											{
												required: true,
												message: 'Seleecione un Tipo de Tarjeta',
											},
										]}>
										<Select
											placeholder='Card Type'
											size='large'
											style={{ width: '100%' }}>
											<Option value={`VISA`}>VISA</Option>
											<Option value={`MASTERCARD`}>MASTERCARD</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>Número de Tarjeta:</h5>
									<InputCustom
										className={''}
										inputName={'AccountNumber'}
										inputNameLabel={'Número de Tarjeta'}
										inputNameMessage={'La cuenta es obligatoria'}
										inputNameType={'text'}
										inputNameRule={true}
										inputNameIcon={''}
										inputNameRules={'rulesNumber'}
									/>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>Fecha de Expiración:</h5>
									<Form.Item
										name='ExpirationDate'
										rules={rulesValidationMask.rulesAge(date)}>
										<InputMask
											maskstyle='est-profile-payment-info-field-input'
											mask='99/9999'
											placeholder='12/2021'
										/>
									</Form.Item>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>Nombre:</h5>
									<InputCustom
										className={''}
										inputName={'Name'}
										inputNameLabel={'Nombre'}
										inputNameMessage={'Campo obligatorio'}
										inputNameType={'text'}
										inputNameRule={true}
										inputNameIcon={''}
										inputNameRules={'rulesNameRequired'}
									/>
								</Col>

								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>Dirección:</h5>
									<InputCustom
										className={''}
										inputName={'Address'}
										inputNameLabel={'Direccion'}
										inputNameMessage={'Campo obligatorio'}
										inputNameType={'text'}
										inputNameRule={true}
										inputNameIcon={''}
										inputNameRules={'rulesAddressRequired'}
									/>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>Ciudad:</h5>
									<InputCustom
										className={''}
										inputName={'City'}
										inputNameLabel={'Ciudad'}
										inputNameMessage={'Campo obligatorio'}
										inputNameType={'text'}
										inputNameRule={true}
										inputNameIcon={''}
										inputNameRules={'rulesNameRequired'}
									/>
								</Col>
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={8}
									className='est-profile-payment-info-container'>
									<h5 className='est-profile-payment-info-title'>Tipo de Transacion:</h5>
									<Form.Item
										name='TypeTransaction'
										rules={[
											{
												required: true,
												message: 'Seleecione un Tipo de transacion',
											},
										]}>
										<Select
											placeholder='Transaction Type'
											size='large'
											style={{ width: '100%' }}>
											<Option value={`DEBIT`}>DEBIT</Option>
											<Option value={`CREDIT`}>CREDIT</Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<div className='est-profile-payment-info-button-container'>
								<Button
									loading={isButtonLoading}
									className='est-profile-payment-info-button'
									htmlType={'submit'}>
									Pagar
								</Button>
							</div>
						</Form>
					</div>
				</>
			)
		}
	} else {
		return (
			<h3>
				Ha ocurrido un error, recargue la pagina, si el error persiste, cominiquese con nuestro
				equipo de soporte
			</h3>
		)
	}
}
