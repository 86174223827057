/** @format */

import React from 'react'

import { RefinementList, ClearRefinements, MenuSelect } from 'react-instantsearch-dom'

import { GoogleMapsLoader, GeoSearch, Marker } from 'react-instantsearch-dom-maps'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import Image from '../../../Image'
import SupportImg from '../../../../../img/detail/Support.png'
import SaleImg from '../../../../../img/detail/sale.png'

import './style.css'

export default function Filters() {
	return (
		<>
			<div className='est-filters-global-contrainer'>
				<GoogleMapsLoader apiKey='AIzaSyCqYJiQ-ZQz86se0rF4_7sbFQ9UBq8Ehrg'>
					{(google) => (
						<GeoSearch google={google} minZoom={2}>
							{({ hits }) => (
								<div>
									{hits.map((hit) => (
										<Marker key={hit.objectID} hit={hit} />
									))}
									<ClearRefinements
										className='ClearGeoRefinement'
										transformItems={(items) =>
											items.filter((item) => item.id === 'boundingBox')
										}
										translations={{
											reset: 'Clear the map refinement',
										}}
									/>
								</div>
							)}
						</GeoSearch>
					)}
				</GoogleMapsLoader>
			</div>
			<div className='est-results-menu-global-container'>
				<h2 className='est-results-menu-filter-title'>Categorias</h2>
				<hr className='est-results-menu-filter-spacer'></hr>

				<div className='est-results-menu-amenities-container'>
					<Row>
						<Col span={24} className='left-container'>
							<h3 className='left-container-title'>Amenidades</h3>
							<RefinementList withSearchBox attribute='amenidades' />
						</Col>
						<Col span={24} className='middle-container'>
							<h3 className='middle-container-title'>Estados</h3>
							<RefinementList attribute='completeAddress.state' />
						</Col>
						<Col span={24} className='right-container'>
							<h3 className='right-container-title'>Categorias</h3>
							<RefinementList attribute='category' />
						</Col>
					</Row>
				</div>

				<h2 className='est-results-menu-filter-title'>Filtrar por precio</h2>
				<hr className='est-results-menu-filter-spacer'></hr>

				<MenuSelect
					attribute='price'
					translations={{
						seeAllOption: 'Precio',
					}}
				/>

				<div className='est-results-menu-support-global-container'>
					<div className='est-results-menu-support-main-container'>
						<div className='est-results-menu-support-img-container'>
							<Image
								classImg={'est-results-menu-support-img'}
								image={SupportImg}
								alt={'Support'}
								title={'Support'}
							/>
						</div>
						<div className='est-results-menu-support-text-container'>
							<h3 className='est-results-menu-support-title'>Soporte 24 hr</h3>
							<h3 className='est-results-menu-support-subtitle'>
								Lorem ipsum is simply a dummy text
							</h3>
						</div>
					</div>
				</div>

				<div className='est-results-menu-global-container'>
					<div className='est-results-menu-main-container'>
						<div className='est-results-menu-img-container'>
							<Image
								classImg={'est-results-menu-img'}
								image={SaleImg}
								alt={'Sale'}
								title={'Sale'}
							/>
						</div>
						<div className='est-results-menu-text-container'>
							<h3 className='est-results-menu-title'>Descuentos</h3>
							<h3 className='est-results-menu-subtitle'>
								Lorem ipsum is simply a dummy text
							</h3>
						</div>
					</div>
				</div>

				<div className='row'>
					<div id='stats' />
				</div>
			</div>
		</>
	)
}
