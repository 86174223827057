/** @format */

import React from 'react'

import Image from '../Image'

import MainLogoColor from '../../../../src/img/footer-top/main-logo-colorfull.png'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import Spacer from '../Spacer'

import './style.css'

export default function FooterTop() {
	return (
		<div className='est-footer-top-global-container'>
			<Row className='est-footer-top-main-container'>
				<Col xs={24} sm={9} md={9} lg={8} xl={8}>
					<div className='est-footer-top-container'>
						<h3 className='est-footer-top-title'>SOBRE NOSOTROS</h3>
						<h3 className='est-footer-top-title'>CATEGORIAS</h3>
						<h3 className='est-footer-top-title'>FAQ</h3>
					</div>
				</Col>
				<Spacer />
				<Col xs={24} sm={3} md={4} lg={3} xl={3}>
					<div className='est-footer-top-container-image'>
						<Image
							classImg={'est-footer-top-image'}
							image={MainLogoColor}
							alt={'Prujula Logo Color'}
							title={'Prujula Logo Color'}
						/>
					</div>
				</Col>
				<Spacer />
				<Col xs={24} sm={9} md={9} lg={8} xl={8}>
					<div className='est-footer-top-container'>
						<h3 className='est-footer-top-title'>PUBLICACIONES</h3>
						<h3 className='est-footer-top-title'>BLOG</h3>
						<h3 className='est-footer-top-title'>CONTACTO</h3>
					</div>
				</Col>
			</Row>
		</div>
	)
}
