/** @format */

import React, { useEffect, useState } from 'react'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import Loading from '../../../components/Common/Loading'
import MainNavigation from '../../../components/Common/MainNavigation'

import Carrousel from '../detail/components/Carrousel'
import Aside from '../detail/components/Aside'
import Coments from '../detail/components/Coments'

import AdsDetail from './services'

import './style.css'

export default function DetailAd(props) {
	const [isDetail, setDetail] = useState(false)
	useEffect(() => {
		AdsDetail(props.match.params.id).then((response) => {
			if (response) {
				setDetail(response)
			} else {
				setTimeout(() => {
					window.location.pathname = '/'
				}, 5000)
			}
		})
	}, [props.match.params.id])
	if (!isDetail) {
		return <Loading />
	} else {
		return (
			<>
				<MainNavigation
					title={'Publicación'}
					linkPrevTitle={'Home'}
					linkNextTitle={'Publicaciones'}
					linkPrev={'/'}
					linkNext={'/'}
				/>
				<div className='est-detail-global-container'>
					<div className='est-detail-main-container'>
						<Row>
							<Col span={24}>
								<div className='est-detail-text-global-container'>
									<div className='est-detail-title-container'>
										<h2 className='est-detail-title'>{isDetail.title}</h2>
									</div>
									<div className='est-detail-subtitle-container'>
										<h3 className='est-detail-subtitle-one'>
											{isDetail.completeAddress.country}
										</h3>
										<h3 className='est-detail-subtitle-two'>|</h3>
										<h3 className='est-detail-subtitle-three'>
											{isDetail.completeAddress.city}
										</h3>
									</div>
								</div>
							</Col>
							<Col xs={24} sm={24} md={24} lg={16} xl={16}>
								<div className='est-detail-col-container'>
									<Carrousel isDetailCarrousel={isDetail} />
								</div>
								<div className='est-detail-coments-container'>
									<Coments item={isDetail} />
								</div>
							</Col>
							<Col xs={24} sm={24} md={24} lg={8} xl={8}>
								<div className='est-detail-aside-container'>
									<Aside item={isDetail} />
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</>
		)
	}
}
