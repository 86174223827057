/** @format */

import React, { useState } from 'react'

import { usePlacesWidget } from 'react-google-autocomplete'

import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Select from 'antd/lib/select'
import notification from 'antd/lib/notification'

import Input from '../../../../components/Common/Inputs/Normal'

import './style.css'

export default function MainSearch(props) {
	const [isLocationGoogle, setLocationGoogle] = useState('')
	const [isSearchLoading, setSearchLoading] = useState(false)
	const { Option } = Select

	const { ref } = usePlacesWidget({
		apiKey: 'AIzaSyCqYJiQ-ZQz86se0rF4_7sbFQ9UBq8Ehrg',
		options: {
			componentRestrictions: { country: 'pr' },
		},
		onPlaceSelected: (place) => {
			setLocationGoogle(place.formatted_address)
		},
	})

	const handleSearchProduct = async (item) => {
		setSearchLoading(true)
		if (!item.title && !item.category && !isLocationGoogle) {
			notification['success']({
				message: 'Advertencia:',
				description: 'Debe llenar al menos uno de los campos requeridos.',
			})
			setSearchLoading(false)
			return
		} else if (item.title && !item.category && !isLocationGoogle) {
			setSearchLoading(false)
			window.location.href = `/search/${item.title}`
		} else if (!item.title && item.category && !isLocationGoogle) {
			setSearchLoading(false)
			window.location.href = `/categories/${item.category}`
		} else if (!item.title && !item.category && isLocationGoogle) {
			setSearchLoading(false)
			window.location.href = `/search/${isLocationGoogle}`
		} else if (item.title && !item.category && isLocationGoogle) {
			setSearchLoading(false)
			window.location.href = `/search/${item.title + ' ' + isLocationGoogle}`
		} else if (!item.title && item.category && isLocationGoogle) {
			setSearchLoading(false)
			window.location.href = `/categories/${item.category}/${isLocationGoogle}`
		} else if (item.title && item.category && !isLocationGoogle) {
			setSearchLoading(false)
			window.location.href = `/categories/${item.category}/${item.title}`
		} else if (item.title && item.category && isLocationGoogle) {
			setSearchLoading(false)
			window.location.href = `/categories/${item.category}/${
				item.title + ' ' + isLocationGoogle
			}`
		}
	}

	return (
		<Form
			className='est-main-search-global-container'
			name='main_search'
			onFinish={handleSearchProduct}>
			<h3 className='est-main-search-main-title'>¡Encuentra donde acampar!</h3>
			<Row>
				<Col className='est-main-search-input-container' span={8}>
					<Input
						className={'est-auth-login-field-input'}
						inputName={'title'}
						inputNameLabel={'¿Que desea Buscar?'}
						inputNameRule={true}
						inputNameType={'text'}
						inputNameIcon={''}
					/>
				</Col>
				<Col className='est-main-search-input-container' span={8}>
					<input
						className='est-main-search-location-input'
						ref={ref}
						onChange={(event) => setLocationGoogle(event.target.value)}
					/>

					{/* 			<Input
						className={'est-auth-login-field-input'}
						inputName={'address'}
						inputNameLabel={'Dirección'}
						inputNameRule={true}
						inputNameType={'text'}
						inputNameIcon={''}
					/> */}
				</Col>
				<Col className='est-main-search-input-container' span={8}>
					<Form.Item name='category'>
						<Select
							placeholder='Categoria'
							size='large'
							style={{ width: '100%' }}
							allowClear={true}>
							{props.isCategories.map((item, index) => (
								<Option value={item.nombre} key={index}>
									{item.nombre}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<Col className='est-main-search-input-button-container' span={24}>
					<Button
						className='est-main-search-input-button'
						htmlType={'submit'}
						loading={isSearchLoading}>
						Buscar
					</Button>
				</Col>
			</Row>
		</Form>
	)
}
