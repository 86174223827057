/** @format */

import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import HeadDescription from '../../../../components/Common/HeadDescription'

import MainMenu from '../../components/MainMenu'
import DataInfo from './components/DataInfo'

import './style.css'

export default function ProfileMetrics() {
	const [isUser] = useState(JSON.parse(localStorage.getItem('userSession')))
	return (
		<>
			<HeadDescription
				title={`Perfil de Usuario - ${isUser.id}`}
				name={'description'}
				content={'Camping Site'}
			/>

			<div className='est-detail-profile-banner-global-container'>
				<Row className='est-detail-profile-banner-main-container'>
					<Col span={24} className='est-detail-profile-banner-title-container'>
						<h3 className='est-detail-profile-banner-title'>Publicaciones</h3>
					</Col>
					<Col className='est-detail-profile-banner-link-container'>
						<Link to='/' className='est-detail-profile-banner-link'>
							Home
						</Link>
						<h3 className='est-detail-profile-banner-slash'>/</h3>
						<Link to='/' className='est-detail-profile-banner-link'>
							Publicaciones
						</Link>
					</Col>
				</Row>
			</div>

			<div className='est-prujula-detail-profile-global-container'>
				<div className='est-prujula-main-container'>
					<div className='est-prujula-detail-profile-main-title-container'>
						<h2 className='est-prujula-detail-profile-main-title'>Información del usuario</h2>
					</div>

					<div className='est-profile-global-container'>
						<div className='est-main-menu-global-container'>
							<MainMenu user={isUser} />
						</div>
						<div className='est-profile-info-container'>
							<DataInfo isUserDetailInfo={isUser} />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
