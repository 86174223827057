/** @format */
import React from 'react'

import MainNavigation from '../../components/Common/MainNavigation'
import HeadDescription from '../../components/Common/HeadDescription'
import Algolia from '../../components/Common/Algolia'

import './style.css'

export default function SearchResults(props) {
	return (
		<>
			<HeadDescription title={`Prujula -`} name={'description'} content={'Camping Site'} />
			<MainNavigation
				title={`Resultados : ${props.match.params.id}`}
				linkPrevTitle={'Home'}
				linkNextTitle={'Publicaciones'}
				linkPrev={'/'}
				linkNext={'/'}
			/>
			<Algolia params={props.match.params} />
		</>
	)
}
