/** @format */

import React from 'react'

import { Link } from 'react-router-dom'

import { CheckCircleOutlined } from '@ant-design/icons'

import './style.css'

export default function ReservationSuccess() {
	return (
		<div className='est-reservation-success-global-container'>
			<div className='est-reservation-success-main-container'>
				<h3 className='est-reservation-success-icon'>
					<CheckCircleOutlined />
				</h3>
				<h3 className='est-reservation-success-title'>¡Felicidades!</h3>
				<h3 className='est-reservation-success-subtitle'>
					Reservación realizada de manera exitosa
				</h3>

				<div className='est-reservation-success-button-container'>
					<Link to='/' className='est-reservation-success-button'>
						Volver al Inicio
					</Link>
				</div>
			</div>
		</div>
	)
}
