/** @format */

import axios from 'axios'

import { setGlobal } from 'reactn'

import notification from 'antd/lib/notification'

import { ENV_CORE } from '../../../Hooks/Variables/Enviroment'

const servicesLogin = {
	async Login(item) {
		let returnResponse
		let data = {
			conEmail: item.email,
			conPassword: item.password,
			conModo: 'directo',
		}
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/auth/login`,
			data: data,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					notification['success']({
						message: 'Aviso:',
						description: 'Ha iniciado sesión correctamente.',
					})
					returnResponse = {
						nombre: response.data.userInfo.nombre,
						apellido: response.data.userInfo.apellido,
						id: response.data.userInfo.id,
						modo: response.data.userInfo.modo,
						email: response.data.userInfo.email,
					}
					localStorage.setItem('userSession', JSON.stringify(returnResponse))
					setGlobal(() => ({
						userEmail: `${returnResponse.nombre} ${returnResponse.apellido}`,
						userData: returnResponse,
					}))
				} else {
					notification['warning']({
						message: `Advertencia: ${response.data.statusCode}`,
						description: `${response.data.mensaje}.`,
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error Servicio`,
					description: 'Verifique su conexión a Internet',
				})
			})
		return returnResponse
	},
	async Register(item) {
		let returnResponse
		let data = {
			regName: item.first_name,
			regLast: item.last_name,
			regEmail: item.email,
			regPassword: item.password,
			regModo: 'directo',
		}
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/auth/user-register`,
			data: data,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					notification['success']({
						message: 'Aviso:',
						description: `${response.data.mensaje}`,
					})
					returnResponse = response
				} else {
					notification['error']({
						message: `Advertencia: ${response.data.statusCode}`,
						description: `${response.data.mensaje}`,
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error Servicio`,
					description: 'Verifique su conexión a Internet',
				})
			})
		return returnResponse
	},
	async Recover(item) {
		let returnResponse
		let data = {
			conEmail: item.email,
		}
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/auth/restore-password`,
			data: data,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					notification['success']({
						message: 'Aviso:',
						description: 'Verifique su correo electrónico.',
					})
					returnResponse = response
				} else {
					notification['warning']({
						message: 'Advertencia:',
						description: 'Usuario no registrado.',
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error Servicio`,
					description: 'Verifique su conexión a Internet',
				})
			})
		return returnResponse
	},
	async LoginGoogle(item) {
		let returnResponse
		let data = {
			conEmail: item.email,
			conFoto: item.imageUrl,
			conNombre: item.givenName,
			conApellido: item.familyName,
			conModo: 'google',
		}

		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/auth/social-login`,
			data: data,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					notification['success']({
						message: 'Aviso:',
						description: 'Ha iniciado sesión correctamente.',
					})
					returnResponse = {
						nombre: response.data.userInfo.nombre,
						apellido: response.data.userInfo.apellido,
						id: response.data.userInfo.id,
						modo: response.data.userInfo.modo,
						email: response.data.userInfo.email,
					}

					localStorage.setItem('userSession', JSON.stringify(returnResponse))

					setGlobal(() => ({
						userEmail: returnResponse.email,
						userData: returnResponse,
					}))
				} else {
					notification['warning']({
						message: `Advertencia: ${response.data.statusCode}`,
						description: `${response.data.mensaje}.`,
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error Servicio`,
					description: 'Verifique su conexión a Internet jorge',
				})
			})
		return returnResponse
	},
	async LoginFacebook(item) {
		let returnResponse
		let data = {
			conEmail: item.email,
			conFoto: item.picture.data.url,
			conNombre: item.name,
			conApellido: '',
			conModo: 'facebook',
		}
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/auth/social-login`,
			data: data,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					notification['success']({
						message: 'Aviso:',
						description: 'Ha iniciado sesión correctamente.',
					})
					returnResponse = {
						nombre: response.data.userInfo.nombre,
						apellido: response.data.userInfo.apellido,
						id: response.data.userInfo.id,
						modo: response.data.userInfo.modo,
						email: response.data.userInfo.email,
					}
					localStorage.setItem('userSession', returnResponse)
					setGlobal(() => ({
						userEmail: returnResponse.email,
						userData: returnResponse,
					}))
				} else {
					notification['warning']({
						message: `Advertencia: ${response.data.statusCode}`,
						description: `${response.data.mensaje}.`,
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error Servicio`,
					description: 'Verifique su conexión a Internet',
				})
			})
		return returnResponse
	},
}
export default servicesLogin
