/** @format */
import React from 'react'

import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, SearchBox, Configure, Pagination } from 'react-instantsearch-dom'

import Filters from './components/Filters'
import Results from './components/Results'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import withURLSync from './services'
import './style.css'

function AlgoliaSearch(props) {
	const searchClient = algoliasearch('RK5WGMMT2Y', '5faaffff77e964237ab79653cb5057ba')

	const handleFilter = (props) => {
		let { category } = props
		const filters = []
		if (props.params.categories) {
			category = props.params.categories
		}
		if (category) {
			filters.push(`category:${category}`)
		}
		return filters.join(' AND ')
	}

	const handleSimilarQuerys = (props) => {
		let { anuncios } = props
		const filters = []
		if (props.params.id) {
			anuncios = props.params.id
			anuncios = anuncios.replaceAll('-', ' ')
		}
		filters.push(`'${anuncios}'`)
		return anuncios
	}

	return (
		<>
			<div className='est-algolia-global-container'>
				<div className='est-algolia-main-container'>
					<InstantSearch
						searchClient={searchClient}
						indexName='publications'
						searchState={props.searchState}
						createURL={props.createURL}
						routing={true}
						onSearchStateChange={props.onSearchStateChange}>
						<Configure
							filters={handleFilter(props)}
							similarQuery={handleSimilarQuerys(props)}
						/>
						<Row>
							<Col span={18} className='est-algolia-results-container'>
								<Results />
							</Col>
							<Col span={6} className='est-algolia-search-filters-container'>
								<h3 className='est-algolia-search-filters-title'>Buscar</h3>
								<hr className='est-algolia-search-filters-spacer'></hr>
								<header className='header search-box-main-container'>
									<SearchBox translations={{ placeholder: 'Ingresa una palabra clave' }} />
								</header>
								<Filters />
							</Col>
							<Col span={24} className='est-algolia-pagination-global-container'>
								<div className='est-algolia-pagination-main-container'>
									<Pagination />
								</div>
							</Col>
						</Row>
					</InstantSearch>
				</div>
			</div>
		</>
	)
}
export default withURLSync(AlgoliaSearch)
