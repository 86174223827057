/** @format */

import React from 'react'
import { Tabs } from 'antd'

import { StarFilled, StarOutlined, LeftSquareFilled, RightSquareFilled } from '@ant-design/icons'

import Image from '../../../../../../src/components/Common/Image'
import ProfileImg from '../../../../../../src/img/detail/profile-example.png'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import './style.css'

export default function Coments(props) {
	const { TabPane } = Tabs
	return (
		<div className='est-comments-global-container'>
			<div className='est-comments-main-container'>
				<div className='est-comments-tab-global-container'>
					<div className='est-comments-tab-main-container'>
						<Tabs defaultActiveKey='1'>
							<TabPane tab='DESCRIPCIÓN' key='1'>
								<div className='tab-main-container'>
									<h3 className='tab-subtitle'>{props.item.description}</h3>
									<h3 className='tab-subtitle'>
										<span className='est-description-subtitle'>
											Cantidad de personas permitidas:
										</span>
										{props.item.people}
									</h3>
									<h3 className='tab-subtitle'>
										<span className='est-description-subtitle'>Se reserva con:</span>
										{parseInt(props.item.discount_amount)}%
									</h3>
								</div>
							</TabPane>
							<TabPane tab='AMENIDADES' key='2'>
								<div className='tab-main-container'>
									<Row>
										<Col span={12}>
											<div className='est-amenities-container'>
												{(props.item.amenidades.camping_mochila === '1' ||
													props.item.amenidades.camping_baul === '1') && (
													<>
														<h2 className='tab-title'>Equipos disponibles:</h2>
														{props.item.amenidades.camping_mochila === '1' && (
															<h3 className='tab-subtitle'>Camping de Mochila</h3>
														)}
														{props.item.amenidades.camping_baul === '1' && (
															<h3 className='tab-subtitle'>Camping de Baúl</h3>
														)}
													</>
												)}
											</div>

											<div className='est-amenities-container'>
												{(props.item.amenidades.agua === '1' ||
													props.item.amenidades.luz === '1' ||
													props.item.amenidades.tocador === '1' ||
													props.item.amenidades.cocinas === '1' ||
													props.item.amenidades.bbq === '1' ||
													props.item.amenidades.fogata === '1') && (
													<>
														<h2 className='tab-title'>Servicios basicos:</h2>
														{props.item.amenidades.agua === '1' && (
															<h3 className='tab-subtitle'>Agua</h3>
														)}
														{props.item.amenidades.luz === '1' && (
															<h3 className='tab-subtitle'>Luz</h3>
														)}
														{props.item.amenidades.tocador === '1' && (
															<h3 className='tab-subtitle'>Baño</h3>
														)}
														{props.item.amenidades.cocinas === '1' && (
															<h3 className='tab-subtitle'>Cocinas</h3>
														)}
														{props.item.amenidades.bbq === '1' && (
															<h3 className='tab-subtitle'>BBQ</h3>
														)}
														{props.item.amenidades.fogata === '1' && (
															<h3 className='tab-subtitle'>Sitio para Fogatas</h3>
														)}
													</>
												)}
											</div>
										</Col>
										<Col span={12}>
											<div className='est-amenities-container'>
												{(props.item.amenidades.equipos_mesas === '1' ||
													props.item.amenidades.equipos_sillas === '1' ||
													props.item.amenidades.equipos_estufas === '1') && (
													<>
														<h2 className='tab-title'>Equipos disponibles:</h2>
														{props.item.amenidades.equipos_mesas === '1' && (
															<h3 className='tab-subtitle'>Mesas</h3>
														)}
														{props.item.amenidades.equipos_sillas === '1' && (
															<h3 className='tab-subtitle'>Sillas</h3>
														)}
														{props.item.amenidades.equipos_estufas === '1' && (
															<h3 className='tab-subtitle'>Estufas</h3>
														)}
													</>
												)}
											</div>

											<div className='est-amenities-container'>
												{(props.item.amenidades.historico === '1' ||
													props.item.amenidades.ecologia === '1' ||
													props.item.amenidades.agricola === '1' ||
													props.item.amenidades.reactivo_pasivo === '1' ||
													props.item.amenidades.reactivo_activo === '1') && (
													<>
														<h2 className='tab-title'>Caracter de lugar:</h2>
														{props.item.amenidades.historico === '1' && (
															<h3 className='tab-subtitle'>Historico</h3>
														)}
														{props.item.amenidades.ecologia === '1' && (
															<h3 className='tab-subtitle'>Reserva Ecologica</h3>
														)}
														{props.item.amenidades.agricola === '1' && (
															<h3 className='tab-subtitle'>
																Agricola (Hidroponicos)
															</h3>
														)}
														{props.item.amenidades.reactivo_pasivo === '1' && (
															<h3 className='tab-subtitle'>Reactivo Pasivo</h3>
														)}
														{props.item.amenidades.reactivo_activo === '1' && (
															<h3 className='tab-subtitle'>Reactivo Activo</h3>
														)}
													</>
												)}
											</div>
										</Col>
										<Col span={24}>
											<div className='est-amenities-container'>
												{(props.item.amenidades.recreacion_piscinas === '1' ||
													props.item.amenidades.recreacion_acuaticas === '1' ||
													props.item.amenidades.recreacion_veredas === '1' ||
													props.item.amenidades.recreacion_espeleologia === '1' ||
													props.item.amenidades.recreacion_kayac_paddle_balsas ===
														'1' ||
													props.item.amenidades.recreacion_cocina === '1' ||
													props.item.amenidades.recreacion_pajaros === '1' ||
													props.item.amenidades.recreacion_alpinismo === '1' ||
													props.item.amenidades.recreacion_zipline === '1' ||
													props.item.amenidades.paracaidas === '1' ||
													props.item.amenidades.recreacion_areas === '1' ||
													props.item.amenidades.recreacion_animales === '1') && (
													<>
														<h2 className='tab-title'>
															Actividades a realizar en el lugar:
														</h2>
														{props.item.amenidades.recreacion_piscinas === '1' && (
															<h3 className='tab-subtitle'>Piscinas</h3>
														)}
														{props.item.amenidades.recreacion_acuaticas === '1' && (
															<h3 className='tab-subtitle'>Acuáticas</h3>
														)}
														{props.item.amenidades.recreacion_veredas === '1' && (
															<h3 className='tab-subtitle'>Veredas, Senderos</h3>
														)}
														{props.item.amenidades.recreacion_espeleologia ===
															'1' && <h3 className='tab-subtitle'>Espeleología</h3>}
														{props.item.amenidades.recreacion_kayac_paddle_balsas ===
															'1' && (
															<h3 className='tab-subtitle'>Kayac, paddle, balsas</h3>
														)}
														{props.item.amenidades.recreacion_cocina === '1' && (
															<h3 className='tab-subtitle'>
																Cocina (estufa y fregadero)
															</h3>
														)}
														{props.item.amenidades.recreacion_pajaros === '1' && (
															<h3 className='tab-subtitle'>
																Avistaiento de Pájaros
															</h3>
														)}
														{props.item.amenidades.recreacion_alpinismo === '1' && (
															<h3 className='tab-subtitle'>Alpinismo</h3>
														)}
														{props.item.amenidades.recreacion_zipline === '1' && (
															<h3 className='tab-subtitle'>Zip-line</h3>
														)}
														{props.item.amenidades.paracaidas === '1' && (
															<h3 className='tab-subtitle'>Paracaídas</h3>
														)}
														{props.item.amenidades.recreacion_areas === '1' && (
															<h3 className='tab-subtitle'>
																Areas de reunion, talleres o conferencias
															</h3>
														)}
														{props.item.amenidades.recreacion_animales === '1' && (
															<h3 className='tab-subtitle'>
																Avistamiento de especies endemicas
															</h3>
														)}
													</>
												)}
											</div>
										</Col>
									</Row>
								</div>
							</TabPane>
							{/* <TabPane tab='COMPARTIR' key='3'>
								<div className='tab-main-container'>
									<h2 className='tab-title'>Lorem compartir</h2>
									<h3 className='tab-subtitle'>
										Pellentesque mauris est, pellentesque eget posuere rhoncus, viverra
										sed nibh.
									</h3>
								</div>
							</TabPane> */}
						</Tabs>
					</div>
				</div>

				<div className='est-points-global-container'>
					<div className='est-points-main-container'>
						<div className='est-points-content-main-container'>
							<div className='est-points-content'>
								<h2 className='est-points-title'>50</h2>
								<h3 className='est-points-subtitle'>Visitantes</h3>
								<h3 className='est-points-subtitle'>Mensuales</h3>
							</div>

							<div className='est-points-content'>
								<h2 className='est-points-title'>120</h2>
								<h3 className='est-points-subtitle'>Reseñas</h3>
							</div>

							<div className='est-points-content'>
								<h2 className='est-points-title'>200</h2>
								<h3 className='est-points-subtitle'>Comentarios</h3>
								<h3 className='est-points-subtitle'>Positivos</h3>
							</div>
						</div>
					</div>
				</div>

				<div className='est-comments-global-container'>
					<Row className='est-comments-main-container'>
						<Col span={24} className='est-comments-title-container'>
							<h3 className='est-comments-title'>Comentarios Destacados</h3>
						</Col>

						<Col span={5} className='est-comments-img-profile-name-container'>
							<div className='est-comments-img-container'>
								<Image
									classImg={'est-comments-img'}
									image={ProfileImg}
									alt={'Profile photo'}
									title={'Profile photo'}
								/>
							</div>
							<div className='est-comments-profile-name-container'>
								<h3 className='est-comments-profile-name-title'>Mario J.</h3>
								<h4 className='est-comments-profile-name-title'>Visitante frecuente</h4>
							</div>
						</Col>

						<Col span={17} className='est-comments-button-star-global-container'>
							<Row className='est-comments-button-star-main-container'>
								<Col>
									<div className='est-comments-star-container'>
										<h3 className='est-comments-star'>
											<StarFilled />
										</h3>
										<h3 className='est-comments-star'>
											<StarFilled />
										</h3>
										<h3 className='est-comments-star'>
											<StarFilled />
										</h3>
										<h3 className='est-comments-star'>
											<StarOutlined />
										</h3>
										<h3 className='est-comments-star'>
											<StarOutlined />
										</h3>
									</div>
								</Col>

								<Col>
									<div className='est-comments-button-container'>
										<h3 className='est-comments-button'>
											<LeftSquareFilled />
										</h3>
										<h3 className='est-comments-button'>
											<RightSquareFilled />
										</h3>
									</div>
								</Col>

								<Col span={24}>
									<h4>
										The Yucatan peninsula nam in maximus odio, sed dignissim massa.
										Phasellus at elit urna. Praesent felis libero, scelerisque at eleifend
										quis, porta vestibulum nunc. Cras in tempor leo, vitae pellentesque
										est. Quisque molestie magna lacus, in laoreet elit feugiat vitae.
										Mauris blandit, metus.
									</h4>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)
}
