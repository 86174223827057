/** @format */

import axios from 'axios'

import notification from 'antd/lib/notification'

export default async function SendPayment(item) {
	let returnResponse
	await axios({
		method: 'POST',
		url: `https://api.ezpaycenters.net/api/cc`,
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		},
		data: item,
	})
		.then((response) => {
			returnResponse = response
			notification['success']({
				message: 'Error:',
				description: `Proceso Completado`,
			})
			/* 	if (response.data.statusCode === 200) {
				returnResponse = response.data
			} else if (response.data.statusCode === 400) {
				notification['warning']({
					message: 'Error:',
					description: `${response.data.mensaje}`,
				})
			} else {
				notification['warning']({
					message: 'Advertencia:',
					description: 'Error en Servicio: REACT_APP_SERVICE_CORE - User Reservations',
				})
			} */
		})
		.catch(() => {
			notification['error']({
				message: `Error`,
				description: 'Verifique su conexión a Internet',
			})
		})
	return returnResponse
}
