/** @format */

import axios from 'axios'

import notification from 'antd/lib/notification'

import { ENV_CORE } from '../../components/Common/Hooks/Variables/Enviroment'

export default async function VerifyAccount(item) {
	let returnResponse
	let data = {
		conVerifyUser: item,
	}
	await axios({
		method: 'POST',
		url: `${ENV_CORE}/api/auth/register-sucess`,
		data: data,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				notification['success']({
					message: 'Aviso:',
					description: 'Usuario verificado correctamente.',
				})
				returnResponse = response
			} else {
				notification['warning']({
					message: `Advertencia: ${response.data.statusCode}`,
					description: `${response.data.mensaje}.`,
				})
			}
		})
		.catch(() => {
			notification['error']({
				message: `Error Servicio`,
				description: 'Verifique su conexión a Internet',
			})
		})
	return returnResponse
}
