/** @format */

import React from 'react'

import { SortBy, Stats, connectHits } from 'react-instantsearch-dom'

import { BarsOutlined } from '@ant-design/icons'
import { StarOutlined } from '@ant-design/icons'
import { StarFilled } from '@ant-design/icons'
import { PlusOutlined } from '@ant-design/icons'
import { SearchOutlined } from '@ant-design/icons'

import Button from 'antd/lib/button'

import './style.css'

export default function RightContent() {
	const MyHits = connectHits(({ hits }) => {
		const hs = hits.map((hit) => <HitComponent key={hit.objectID} hit={hit} />)
		return <div id='hits'>{hs}</div>
	})

	function HitComponent({ hit }) {
		return (
			<div className='est-algolia-hits-global-container'>
				<div className='est-algolia-hits-img-container'>
					<img className='est-algolia-hits-img' alt={hit.title} src={hit.picture_url} />
				</div>

				<div className='est-algolia-hits-main-container'>
					<div className='est-algolia-hits-title-review-container'>
						<div className='est-algolia-hits-title-price-container'>
							<h3 className='est-algolia-hits-title'>{hit.title}</h3>
							<div className='est-algolia-hits-price'>$ {hit.price}</div>
							{/* <img className='profile' alt={hit.user.nombre} src={hit.user.foto} /> */}
						</div>
						<div className='est-algolia-hits-review-global-container'>
							<h3 className='est-algolia-hits-review-icon'>
								<StarFilled />
								<StarOutlined />
								<StarOutlined />
								<StarOutlined />
								<StarOutlined />
							</h3>
							<h4 className='est-algolia-hits-review'>( Basado en {hit.vistas} reviews )</h4>
						</div>
					</div>

					<div className='est-algolia-hits-info-global-container'>
						<h4 className='est-algolia-hits-info-description'>{hit.description}</h4>
						{/* <h4 className='est-algolia-hits-info-city'>{hit.completeAddress.city}</h4>
					<h4 className='est-algolia-hits-info-country'>{hit.completeAddress.country}</h4> */}
					</div>

					<div className='est-algolia-hits-button-container'>
						<Button className='est-algolia-hits-button'>
							<PlusOutlined /> Ver más
						</Button>
						<Button className='est-algolia-hits-search-button'>
							<SearchOutlined />
						</Button>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className='container-fluid' id='results'>
			<div className='est-results-global-container'>
				<div className='est-results-sort-global-container'>
					<div className='est-results-info-area-container'>
						<h3 className='est-results-info-area-icon'>
							<BarsOutlined />
						</h3>
						<Stats />
					</div>
					{/* 
					<div id='searchbox'>
						<HitsPerPage
							defaultRefinement={3}
							items={[{ value: 3 }, { value: 12 }, { value: 24 }, { value: 48 }]}
						/>
					</div>
					 */}
					<div className='est-results-sort-container'>
						<h3 className='est-results-sort-title'>Ordenar por:</h3>
						<SortBy
							defaultRefinement='publications'
							items={[
								{ label: 'Relevantes', value: 'publications' },
								{ label: 'Mayor Precio', value: 'publications_price_asc' },
								{ label: 'Menor Precio', value: 'publications_price_desc' },
							]}
						/>
					</div>
				</div>
				<hr className='est-results-spacer'></hr>
				<MyHits />
			</div>
		</div>
	)
}
