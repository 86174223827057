/** @format */

import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Radio from 'antd/lib/radio'
import Switch from 'antd/lib/switch'
import Select from 'antd/lib/select'
import notification from 'antd/lib/notification'

import Spacer from '../../../../../components/Common/Spacer'

import Loading from '../../../../../components/Common/Loading'

import { rulesValidation } from '../../../../../components/Common/Inputs/Normal/rules'

import InputCustom from '../../../../../components/Common/Inputs/Normal'

import Categories from './services'

import './style.css'

export default function AdsBasicInfo() {
	const [isUser] = useState(JSON.parse(localStorage.getItem('userSession')))
	const [isCategories, setCategories] = useState(false)
	const [isDiscountPrice, setDiscountPrice] = useState(false)
	const { TextArea } = Input
	const { Option } = Select

	const handleBasicInfo = async (item) => {
		item.regIdUser = isUser.id
		if (!item.regOffer) {
			item.regOffer = false
		}
		if (!item.regPriceOffer) {
			item.regPriceOffer = '0'
		}
		if (parseInt(item.regPriceOffer) > parseInt(item.regPrice)) {
			notification['success']({
				message: 'Aviso:',
				description:
					'El precio de Oferta debe ser menor al precio Estandard de la publicación.',
			})
			return
		}
		localStorage.setItem('info', JSON.stringify(item))
		window.location.pathname = 'publication/create/location'
	}

	const handleDiscountPrice = async () => {
		if (isDiscountPrice) {
			setDiscountPrice(false)
		} else {
			setDiscountPrice(true)
		}
	}

	useEffect(() => {
		localStorage.removeItem('success')
		localStorage.removeItem('info')
		localStorage.removeItem('info-location')
		localStorage.removeItem('info-photos')
		Categories().then((response) => {
			if (response) {
				setCategories(response)
			}
		})
	}, [])

	if (!isCategories) {
		return <Loading />
	} else {
		return (
			<div className='est-ads-basics-info-global-container'>
				<h2 className='est-ads-basics-info-global-title'>
					Formulario de publicación de Arrendador
				</h2>
				<h3 className='est-ads-basics-info-global-subtitle'>Información Basica:</h3>
				<Form
					name='basic_info'
					onFinish={handleBasicInfo}
					initialValues={{
						regHalf: 'default',
					}}>
					<Row className='est-ads-basics-info-main-container'>
						<Col
							xs={24}
							sm={isDiscountPrice ? 8 : 12}
							md={isDiscountPrice ? 8 : 12}
							lg={isDiscountPrice ? 8 : 12}
							xl={isDiscountPrice ? 8 : 12}
							className='est-ads-basics-info-container'>
							<h4 className='est-ads-basics-info-title'>Nombre del sitio:</h4>
							<InputCustom
								className={'est-ads-basics-info-field-input'}
								inputName={'regTitle'}
								inputNameLabel={'Nombre del Sitio'}
								inputNameRule={true}
								inputNameMessage={' '}
								inputNameType={'text'}
								inputNameIcon={''}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col
							xs={24}
							sm={isDiscountPrice ? 8 : 12}
							md={isDiscountPrice ? 8 : 12}
							lg={isDiscountPrice ? 8 : 12}
							xl={isDiscountPrice ? 8 : 12}
							className='est-ads-basics-info-container'>
							<h4 className='est-ads-basics-info-title'>Precio:</h4>
							<InputCustom
								className={'est-ads-basics-info-field-input'}
								inputName={'regPrice'}
								inputNameLabel={'Precio'}
								inputNameRule={true}
								inputNameMessage={' '}
								inputNameType={'number'}
								inputNameIcon={''}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						{isDiscountPrice && (
							<Col
								xs={24}
								sm={8}
								md={8}
								lg={8}
								xl={8}
								className='est-ads-basics-info-container'>
								<h4 className='est-ads-basics-info-title'>Precio Oferta:</h4>
								<InputCustom
									className={'est-ads-basics-info-field-input'}
									inputName={'regPriceOffer'}
									inputNameLabel={'Precio Oferta'}
									inputNameRule={true}
									inputNameMessage={' '}
									inputNameType={'number'}
									inputNameIcon={''}
									inputNameRules={'rulesRequired'}
								/>
							</Col>
						)}
						<Col span={24} className='est-ads-basics-info-container'>
							<h4 className='est-ads-basics-info-title'>Descripción:</h4>
							<Form.Item name={'regDescription'} rules={rulesValidation.rulesRequired}>
								<TextArea
									className='est-ads-basics-info-field-input'
									size='large'
									type={'text'}
									placeholder={'Descripción'}
									rows={4}
								/>
							</Form.Item>
						</Col>
						<div className='field-hidden'>
							<InputCustom
								className={'est-ads-basics-info-field-input'}
								inputName={'regHalf'}
								inputNameLabel={'Medio'}
								inputNameRule={true}
								inputNameMessage={' '}
								inputNameType={'text'}
								inputNameIcon={''}
								inputNameRules={'rulesRequired'}
							/>
						</div>
						<Col
							xs={24}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							className='est-ads-basics-info-container'>
							<h4 className='est-ads-basics-info-title'>Cantidad de personas permitidas:</h4>
							<InputCustom
								className={'est-ads-basics-info-field-input'}
								inputName={'regPeople'}
								inputNameLabel={'No. de personas'}
								inputNameRule={true}
								inputNameMessage={' '}
								inputNameType={'number'}
								inputNameIcon={''}
								inputNameRules={'rulesRequired'}
							/>
						</Col>
						<Col
							xs={24}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							className='est-bacics-info-category-container'>
							<Form.Item
								name='regIdCategory'
								rules={[
									{
										required: true,
										message: 'Ingrese una categoria.',
									},
								]}>
								<Select placeholder='Categoria' size='large' style={{ width: '100%' }}>
									{isCategories.map((item, index) => (
										<Option value={item.id} key={index}>
											{item.nombre}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={6} sm={5} md={5} lg={4} xl={4} className='est-ads-basics-info-container'>
							<h4 className='est-ads-basics-info-title'>Descuento:</h4>
							<Form.Item name='regOffer' valuePropName='checked'>
								<Switch onClick={() => handleDiscountPrice()} />
							</Form.Item>
						</Col>
						<Col
							xs={17}
							sm={10}
							md={8}
							lg={7}
							xl={5}
							className='est-ads-basics-info-container'>
							<h4 className='est-ads-basics-info-title'>Se reserva con:</h4>
							<Form.Item
								name={'regDiscountAmount'}
								rules={rulesValidation.rulesRequired}
								className='est-ads-basics-info-value-container'>
								<Radio.Group>
									<Radio value={100}>100%</Radio>
									<Radio value={50}>50%</Radio>
									<Radio value={25}>25%</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24} className='est-ads-basics-info-button-container'>
							<Link className='est-ads-basics-info-button' to='/'>
								Ir al Inicio
							</Link>
							<Spacer />
							<Button className='est-ads-basics-info-button' htmlType={'submit'}>
								Siguiente
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		)
	}
}
