/** @format */

import React from 'react'
import { setGlobal } from 'reactn'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'

import Spacer from '../../../../../../components/Common/Spacer'

import './style.css'

export default function DataInfo() {
	return (
		<div className='est-profile-data-info-global-container'>
			<div className='est-profile-data-info-main-container'>
				<Button
					type='primary'
					onClick={() =>
						setGlobal({
							useDrawer: true,
						})
					}>
					Menú
				</Button>
				<Row>
					<Col xs={24} sm={24} md={24} lg={8} xl={8} className='est-profile-data-info-box'>
						<h3 className='est-profile-data-info-title-one'>Lorem Ipsum</h3>
						<h4 className='est-profile-data-info-text-one'>Lorem Ipsum</h4>
						<h3 className='est-profile-data-info-text-two'>2.4586</h3>
					</Col>
					<Spacer />
					<Col xs={24} sm={24} md={24} lg={8} xl={8} className='est-profile-data-info-box'>
						<h3 className='est-profile-data-info-title-one'>Lorem Ipsum</h3>
						<h4 className='est-profile-data-info-text-one'>Lorem Ipsum</h4>
						<h3 className='est-profile-data-info-text-two'>7.415$</h3>
					</Col>
					<Spacer />
					<Col xs={24} sm={24} md={24} lg={6} xl={6} className='est-profile-data-info-box'>
						<h3 className='est-profile-data-info-title-one'>Lorem Ipsum</h3>
						<h4 className='est-profile-data-info-text-one'>Lorem Ipsum</h4>
						<h3 className='est-profile-data-info-text-two'>2.45</h3>
					</Col>
				</Row>
				<Row>
					<Col xs={24} sm={24} md={24} lg={17} xl={17} className='est-profile-data-info-box'>
						<h3 className='est-profile-data-info-title-one'>Lorem Ipsum</h3>
						<h4 className='est-profile-data-info-text-one'>Lorem Ipsum</h4>
					</Col>
					<Spacer />
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={6}
						xl={6}
						className='est-profile-data-info-box box-green-bg'>
						<h3 className='est-profile-data-info-title-two'>Lorem Ipsum</h3>
						<h4 className='est-profile-data-info-text-three'>Lorem Ipsum</h4>
					</Col>
				</Row>
				<Row>
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={6}
						xl={6}
						className='est-profile-data-info-box-list'>
						<h3 className='est-profile-data-info-title-one'>Lorem Ipsum</h3>
						<h4 className='est-profile-data-info-text-one'>Lorem Ipsum</h4>
						<ul className='est-profile-data-info-list'>
							<li className='est-profile-data-info-list-item'>
								Lorem ipsum solor sit amet, consectetuer diam
							</li>
							<li className='est-profile-data-info-list-item'>
								Lorem ipsum solor sit amet, consectetuer diam
							</li>
							<li className='est-profile-data-info-list-item'>
								Lorem ipsum solor sit amet, consectetuer diam
							</li>
							<li className='est-profile-data-info-list-item'>
								Lorem ipsum solor sit amet, consectetuer diam
							</li>
						</ul>
					</Col>
					<Spacer />
					<Col xs={24} sm={24} md={24} lg={17} xl={17} className='est-profile-data-info-box'>
						<h3 className='est-profile-data-info-title-one'>Lorem Ipsum</h3>
						<h4 className='est-profile-data-info-text-one'>Lorem Ipsum</h4>
					</Col>
				</Row>
			</div>
		</div>
	)
}
