/** @format */

import axios from 'axios'

import notification from 'antd/lib/notification'

import { ENV_CORE } from '../../../../components/Common/Hooks/Variables/Enviroment'

const servicesPublications = {
	async UserPublications(item) {
		let returnResponse
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/publication/user-publications`,
			data: { conIdUser: item },
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response.data.userPublic
				} else if (response.data.statusCode === 400) {
					notification['warning']({
						message: 'Error:',
						description: `${response.data.mensaje}`,
					})
				} else {
					notification['warning']({
						message: 'Advertencia:',
						description: 'Error en Servicio: REACT_APP_SERVICE_CORE - User Publications',
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: 'Verifique su conexión a Internet',
				})
			})
		return returnResponse
	},
	async UpdatePublication(item, data) {
		let info = {
			updId: data,
			updEstatus: item,
		}
		let returnResponse
		await axios({
			method: 'POST',
			url: `${ENV_CORE}/api/publication/update-publication`,
			data: info,
		})
			.then((response) => {
				if (response.data.statusCode === 200) {
					returnResponse = response
					notification['success']({
						message: 'Advertencia:',
						description: `${response.data.mensaje}`,
					})
				} else {
					notification['warning']({
						message: 'Advertencia:',
						description: 'Error en actualizar la Publicacion.',
					})
				}
			})
			.catch(() => {
				notification['error']({
					message: `Error`,
					description: 'Verifique su conexión a Internet',
				})
			})
		return returnResponse
	},
}
export default servicesPublications
