/** @format */

import ImageExample from '../../../../img/blog/young-couple-sitting.png'

export const BLOG = [
	{
		image: ImageExample,
		name: '¡Esta atento a nuestras futuras actualizaciones de contenido!',
		subtitle: 'Por Prujula Owner',
		description:
			'Queremos brindarte la mejor experiencia posible, por eso trabajamos para ti elaborando el mejor contenido posible y de calidad',
		link: 'Pronto',
	},
	/* {
		image: ImageExample,
		name:
			'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
		subtitle: 'Por Catherine, Abril 15 2021',
		description:
			'has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scram bledspecimen book.',
		link: 'Leer más',
	},
	{
		image: ImageExample,
		name:
			'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
		subtitle: 'Por Catherine, Abril 15 2021',
		description:
			'has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scram bledspecimen book.',
		link: 'Leer más',
	}, */
]
