/** @format */

import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Col from 'antd/lib/col'
import Checkbox from 'antd/lib/checkbox'
import Button from 'antd/lib/button'

import Spacer from '../../../../../components/Common/Spacer'

import CreateAd from './services'

import './style.css'

export default function Amenities() {
	const [isInfoAmenities] = useState(JSON.parse(localStorage.getItem('info-photos')))
	const [isLoading, setLoading] = useState(false)

	const handleAmenities = async (item) => {
		let info = isInfoAmenities
		info.regAmenities = item
		setLoading(true)
		CreateAd(info).then((response) => {
			if (response) {
				setLoading(false)
				localStorage.setItem('success', JSON.stringify(response))
				window.location.pathname = 'publication/create/success'
			} else {
				setLoading(false)
				window.location.pathname = 'publication/create/error'
			}
		})
	}

	useEffect(() => {
		if (!isInfoAmenities) {
			window.location.pathname = '/'
		}
	}, [isInfoAmenities])
	return (
		<div className='est-ads-amenities-global-container'>
			<h1 className='est-ads-amenities-global-title'>Formulario de publicación de Arrendador</h1>
			<h4 className='est-ads-amenities-global-subtitle'>Amenidades:</h4>
			<Form
				className='est-ads-amenities-form-container'
				name='basic_info'
				onFinish={handleAmenities}
				initialValues={{
					camping_mochila: false,
					camping_baul: false,
					agua: false,
					luz: false,
					tocador: false,
					cocinas: false,
					bbq: false,
					fogata: false,
					historico: false,
					ecologia: false,
					agricola: false,
					reactivo_pasivo: false,
					reactivo_activo: false,
					recreacion_piscinas: false,
					recreacion_acuaticas: false,
					recreacion_veredas: false,
					recreacion_espeleologia: false,
					recreacion_kayac_paddle_balsas: false,
					recreacion_cocina: false,
					recreacion_pajaros: false,
					recreacion_alpinismo: false,
					recreacion_zipline: false,
					paracaidas: false,
					recreacion_areas: false,
					recreacion_animales: false,
					equipos_mesas: false,
					equipos_sillas: false,
					equipos_estufas: false,
				}}>
				<Row className='est-ads-amenities-main-container'>
					<Col span={24} className='est-amenities-checkbox-container'>
						<h4 className='est-amenities-main-title'>Equipos disponibles:</h4>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item
									className='est-amenities-checkbox'
									name='camping_mochila'
									valuePropName='checked'>
									<Checkbox>Camping de mochila</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='camping_baul' valuePropName='checked'>
									<Checkbox>Camping de baul</Checkbox>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={24} className='est-amenities-checkbox-container'>
						<h4 className='est-amenities-main-title'>Servicios basicos:</h4>
						<Row>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='agua' valuePropName='checked'>
									<Checkbox>Agua</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='luz' valuePropName='checked'>
									<Checkbox>Luz</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='tocador' valuePropName='checked'>
									<Checkbox>Baño</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='cocinas' valuePropName='checked'>
									<Checkbox>Cocinas</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='bbq' valuePropName='checked'>
									<Checkbox>BBQ</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='fogata' valuePropName='checked'>
									<Checkbox>Sitio para fogatas</Checkbox>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={24} className='est-amenities-checkbox-container'>
						<h4 className='est-amenities-main-title'>Caracter de lugar:</h4>
						<Row>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='historico' valuePropName='checked'>
									<Checkbox>Historico</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='ecologia' valuePropName='checked'>
									<Checkbox>Reserva ecologica</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='agricola' valuePropName='checked'>
									<Checkbox>Agricola (Hidroponicos)</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='reactivo_pasivo' valuePropName='checked'>
									<Checkbox>Reactivo pasivo</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='reactivo_activo' valuePropName='checked'>
									<Checkbox>Reactivo activo</Checkbox>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={24} className='est-amenities-checkbox-container'>
						<h4 className='est-amenities-main-title'>Actividades a realizar en el lugar:</h4>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='recreacion_piscinas' valuePropName='checked'>
									<Checkbox>Piscinas</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='recreacion_acuaticas' valuePropName='checked'>
									<Checkbox>Acuaticas</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='recreacion_veredas' valuePropName='checked'>
									<Checkbox>Veredas, senderos</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='recreacion_espeleologia' valuePropName='checked'>
									<Checkbox>Espeleologia</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='recreacion_kayac_paddle_balsas' valuePropName='checked'>
									<Checkbox>Kayac, paddle, balsas</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='recreacion_cocina' valuePropName='checked'>
									<Checkbox>Cocina (estufa y fregadero)</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='recreacion_pajaros' valuePropName='checked'>
									<Checkbox>Avistaiento de pajaros</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='recreacion_alpinismo' valuePropName='checked'>
									<Checkbox>Alpinismo</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='recreacion_zipline' valuePropName='checked'>
									<Checkbox>Zip-line</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='paracaidas' valuePropName='checked'>
									<Checkbox>Paracaidas</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='recreacion_areas' valuePropName='checked'>
									<Checkbox>Areas de reunion, talleres o conferencias</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} md={12} lg={12} xl={12}>
								<Form.Item name='recreacion_animales' valuePropName='checked'>
									<Checkbox>Avistamiento de especies endemicas</Checkbox>
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={24} className='est-amenities-checkbox-container'>
						<h4 className='est-amenities-main-title'>Equipos disponibles:</h4>
						<Row>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='equipos_mesas' valuePropName='checked'>
									<Checkbox>Mesas</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='equipos_sillas' valuePropName='checked'>
									<Checkbox>Sillas</Checkbox>
								</Form.Item>
							</Col>
							<Col xs={12} sm={12} md={8} lg={8} xl={8}>
								<Form.Item name='equipos_estufas' valuePropName='checked'>
									<Checkbox>Estufas</Checkbox>
								</Form.Item>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col span={24} className='est-ads-amenities-button-container'>
						<Link className='est-ads-amenities-button' to='/publication/create/photos'>
							Anterior
						</Link>
						<Spacer />
						<Button
							className='est-ads-amenities-button'
							htmlType={'submit'}
							loading={isLoading}>
							Publicar
						</Button>
					</Col>
				</Row>
			</Form>
		</div>
	)
}
