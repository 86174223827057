/** @format */

import React, { useState, useEffect } from 'react'
import { useGlobal, setGlobal } from 'reactn'
import { Link } from 'react-router-dom'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import HeadDescription from '../../../../components/Common/HeadDescription'
import Loading from '../../../../components/Common/Loading'

import MainMenu from '../../components/MainMenu'

import { GetUserInfo } from '../../../../components/Common/Hooks/Functions/GetUserInfo'

import ProfileInfo from './components/ProfileInfo'

import './style.css'

export default function Profile() {
	const [isUser] = useState(JSON.parse(localStorage.getItem('userSession')))
	const [isDataProfile] = useGlobal('DataProfile')
	useEffect(() => {
		GetUserInfo(isUser.id).then((response) => {
			if (response) {
				setGlobal({
					DataProfile: response,
				})
			} else {
				setTimeout(() => {
					window.location.pathname = '/'
				}, 5000)
			}
		})
	}, [isUser.id])
	if (!isDataProfile) {
		return <Loading />
	} else {
		return (
			<>
				<HeadDescription
					title={`Perfil de Usuario - ${isUser.nombre} - ${isUser.id}`}
					name={'description'}
					content={'Camping Site'}
				/>

				<div className='est-detail-profile-banner-global-container'>
					<Row className='est-detail-profile-banner-main-container'>
						<Col span={24} className='est-detail-profile-banner-title-container'>
							<h3 className='est-detail-profile-banner-title'>Publicaciones</h3>
						</Col>
						<Col className='est-detail-profile-banner-link-container'>
							<Link to='/' className='est-detail-profile-banner-link'>
								Home
							</Link>
							<h3 className='est-detail-profile-banner-slash'>/</h3>
							<Link to='/' className='est-detail-profile-banner-link'>
								Publicaciones
							</Link>
						</Col>
					</Row>
				</div>

				<div className='est-prujula-detail-profile-global-container'>
					<div className='est-prujula-main-container'>
						<div className='est-prujula-detail-profile-main-title-container'>
							<h2 className='est-prujula-detail-profile-main-title'>
								Información del usuario
							</h2>
						</div>

						<div className='est-profile-global-container'>
							<div className='est-profile-aside-container'>
								<MainMenu user={isDataProfile} />
							</div>
							<div className='est-profile-info-container'>
								<ProfileInfo isUserProfileInfo={isDataProfile} />
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}
