/** @format */

import React from 'react'
import { Modal, Button } from 'antd'

import { setGlobal } from 'reactn'

import './style.css'

export default function ModalLogOut(props) {
	const handleLogOut = () => {
		localStorage.removeItem('userSession')
		setGlobal(() => ({ userEmail: null, userData: null }))
		props.showModalLogOut(false)
		window.location.pathname = '/'
	}
	return (
		<>
			<Modal
				wrapClassName='ph-profile-modal-container'
				centered
				title='Cerrar Sesión'
				visible={props.visible}
				onCancel={() => props.showModalLogOut(false)}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				okText=''
				cancelText=''
				footer={false}>
				<p className='ph-profile-modal-title'>¿Estas seguro que quieres Cerrar Sesión?</p>
				<p className='ph-profile-modal-description'>¡No olvides visitarnos pronto!</p>
				<Button className='ph-profile-modal-button' onClick={() => handleLogOut()}>
					Aceptar
				</Button>
			</Modal>
		</>
	)
}
