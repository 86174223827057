/** @format */
import React, { useState, useEffect } from 'react'
import { useGlobal, setGlobal } from 'reactn'

import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Modal from 'antd/lib/modal'
import Form from 'antd/lib/form'
import Button from 'antd/lib/button'

import Input from '../../../Inputs/Normal'

import { ENV_API_GOOGLE, ENV_API_FACEBOOK } from '../../../Hooks/Variables/Enviroment'

import servicesLogin from './services'

import './style.css'

export default function ModalLoginUser() {
	const [form] = Form.useForm()
	const [isModalUser, setModalUser] = useGlobal('ModalUser')
	const [isUserLogin] = useGlobal('UserLogin')
	const [isUserRegister] = useGlobal('UserRegister')
	const [isUserRecover] = useGlobal('UserRecover')
	const [isLoading, setLoading] = useState(false)
	const [setLoginGoogle] = useState(false)
	const [setLoginFacebook] = useState(false)
	const [isSocialLogin, setSocialLogin] = useState('null')

	const handleLoginUser = async (item) => {
		if (isSocialLogin === 'google') {
			//desde aqui no me funciona cuando actualizo el isSocialLogin
			setLoginGoogle(true)

			await servicesLogin.LoginGoogle(item).then((response) => {
				if (response) {
					setLoginGoogle(false)
					handleCloseModal()
				}
			})
		} else if (isSocialLogin === 'facebook') {
			setLoginFacebook(true)

			await servicesLogin.LoginFacebook(item).then((response) => {
				if (response) {
					setLoginFacebook(false)
					handleCloseModal()
				}
			})
		} else {
			setLoading(true)
			if (isUserLogin) {
				await servicesLogin.Login(item).then((response) => {
					if (response) {
						setLoading(false)
						handleCloseModal()
					}
				})
				setLoading(false)
			} else if (isUserRegister) {
				await servicesLogin.Register(item).then((response) => {
					setLoading(false)
					handleCloseModal()
					if (response) {
						window.location.href = '/register-verify'
					}
				})
			} else if (isUserRecover) {
				await servicesLogin.Recover(item).then((response) => {
					setLoading(false)
					handleCloseModal()
					if (response) {
						window.location.href = '/register-verify'
					}
				})
			}
		}
	}

	const handleCloseModal = () => {
		setModalUser(false)
		setGlobal({
			UserLogin: false,
			UserRegister: false,
			UserRecover: false,
		})
	}

	const handleActionsModalUser = (item) => {
		if (item === 'login') {
			setGlobal({
				UserLogin: true,
				UserRegister: false,
				UserRecover: false,
			})
		} else if (item === 'register') {
			setGlobal({
				UserLogin: false,
				UserRegister: true,
				UserRecover: false,
			})
		} else if (item === 'recover') {
			setGlobal({
				UserLogin: false,
				UserRegister: false,
				UserRecover: true,
			})
		}
	}

	const handleResponseGoogle = async (response) => {
		setSocialLogin('google')
		if (response.profileObj !== undefined) {
			await servicesLogin.LoginGoogle(response.profileObj).then((response) => {
				if (response) {
					handleCloseModal()
				}
			})
		}
	}

	const handleResponsefacebook = async (response) => {
		setSocialLogin('facebook')
		await servicesLogin.LoginFacebook(response).then((response) => {
			if (response) {
				handleCloseModal()
			}
		})
	}

	useEffect(() => {
		form.resetFields()
	}, [form, isModalUser])

	return (
		<>
			<Modal
				forceRender
				wrapClassName='est-user-modal-container'
				maskClosable={true}
				width='700px'
				centered
				visible={isModalUser}
				onCancel={() => handleCloseModal()}
				okText='Confirmar'
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}>
				{isUserLogin && <h3>Inicio de Sesión:</h3>}
				{isUserRegister && <h3>Registro de Usuario:</h3>}
				{isUserRecover && <h3>Recuperar Contraseña:</h3>}
				<Form
					form={form}
					initialValues={{
						first_name: '',
						last_name: '',
						email: '',
						confirmEmail: '',
						password: '',
						confirm: '',
					}}
					name='user_login'
					onFinish={handleLoginUser}>
					<div className='est-auth-login-form-container'>
						{isUserRegister && (
							<>
								<Input
									className={'est-auth-register-field-input'}
									inputName={'first_name'}
									inputNameLabel={'Nombre'}
									inputNameRule={true}
									inputNameMessage={'Ingresa tu nombre'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={'rulesFirstName'}
								/>
								<Input
									className={'est-auth-register-field-input'}
									inputName={'last_name'}
									inputNameLabel={'Apellido'}
									inputNameRule={true}
									inputNameMessage={'Ingresa tu apellido'}
									inputNameType={'text'}
									inputNameIcon={''}
									inputNameRules={'rulesLastName'}
								/>
							</>
						)}
						<Input
							className={'est-auth-login-field-input'}
							inputName={'email'}
							inputNameLabel={'Correo electrónico'}
							inputNameRule={true}
							inputNameMessage={'E-mail es obligatorio'}
							inputNameType={'text'}
							inputNameIcon={''}
							inputNameRules={'rulesEmail'}
						/>
						{isUserRegister && (
							<Input
								className={'est-auth-register-field-input'}
								inputName={'confirmEmail'}
								inputNameLabel={'Confirmar E-mail'}
								inputNameRule={true}
								inputNameMessage={'Ingrese su E-mail'}
								inputNameType={'text'}
								inputNameIcon={''}
								inputNameRules={'confirmEmail'}
							/>
						)}
						{(isUserRegister || isUserLogin) && (
							<Input
								className={'est-auth-login-field-input'}
								inputName={'password'}
								inputNameLabel={'Contraseña'}
								inputNameRule={true}
								inputNameMessage={'La contraseña es obligatoria'}
								inputNameType={'password'}
								inputNameIcon={''}
								inputNameRules={'rulesPassword'}
							/>
						)}
						{isUserRegister && (
							<Input
								className={'est-auth-register-field-input'}
								inputName={'confirm'}
								inputNameLabel={'Confirmar Contraseña'}
								inputNameRule={true}
								inputNameMessage={'Ingresa tu contraseña'}
								inputNameType={'password'}
								inputNameIcon={''}
								dependencies={['password']}
								hasFeedback
								inputNameRules={'confirmPassword'}
							/>
						)}
					</div>
					<Form.Item>
						<div>
							<div className='est-main-form-button-main-container'>
								<Button
									onClick={() => setSocialLogin(null)}
									className='est-main-form-main-green-button'
									type='primary'
									htmlType={'submit'}
									loading={isLoading}>
									{isUserRecover && 'Recuperar Contraseña'}
									{isUserRegister && 'Registrarse'}
									{isUserLogin && 'Iniciar Sesión'}
								</Button>
							</div>

							{(isUserRegister || isUserLogin) && (
								<Row className='est-modal-user-social-button-main-container'>
									<Col
										xs={24}
										sm={24}
										md={12}
										lg={12}
										xl={12}
										className='est-modal-user-social-button-container'>
										<GoogleLogin
											clientId={`${ENV_API_GOOGLE}`}
											onSuccess={handleResponseGoogle}
											onFailure={handleResponseGoogle}
											className='est-main-form-main-button'
											icon={false}>
											<span>Google</span>
										</GoogleLogin>
									</Col>
									<Col
										xs={24}
										sm={24}
										md={12}
										lg={12}
										xl={12}
										className='est-modal-user-social-button-container'>
										<FacebookLogin
											appId={`${ENV_API_FACEBOOK}`}
											autoLoad={false}
											fields='name,email,picture'
											textButton='Facebook'
											cssClass='est-main-form-main-button'
											callback={handleResponsefacebook}
										/>
									</Col>
								</Row>
							)}
							<br />
							<h3>Actions:</h3>
							{!isUserRecover && (
								<div onClick={() => handleActionsModalUser('recover')}>
									<h3 className='est-user-modal-title'>Olvidé mi contraseña</h3>
								</div>
							)}
							{!isUserRegister && (
								<div onClick={() => handleActionsModalUser('register')}>
									<h3 className='est-user-modal-title'>Registrarse</h3>
								</div>
							)}
							{!isUserLogin && (
								<div onClick={() => handleActionsModalUser('login')}>
									<h3 className='est-user-modal-title'>Iniciar Sesión</h3>
								</div>
							)}
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}
