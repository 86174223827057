/** @format */

import React, { useState } from 'react'

import { ArrowRightOutlined } from '@ant-design/icons'

import { ENV_CORE } from '../../../../../../components/Common/Hooks/Variables/Enviroment'

import Image from '../../../../../../components/Common/Image'

import './style.css'

export default function CardCategories(props) {
	const [isShow, setShow] = useState(false)

	const handleSearchLink = async (item) => {
		let params = {}
		params.category = item
		window.location.href = `/categories/${JSON.stringify(params)}`
	}

	return (
		<div
			className='est-categories-global-container'
			onMouseEnter={() => setShow(true)}
			onMouseLeave={() => setShow(false)}
			onClick={() => handleSearchLink(props.item.nombre)}>
			<div
				className={`${
					isShow
						? 'est-categories-main-container-shown'
						: 'est-categories-main-container-unshown'
				} est-categories-main-container`}>
				<Image
					classImg={`${
						isShow ? 'est-card-categories-img-little' : 'est-card-categories-img-big'
					} est-card-categories-img`}
					image={`${ENV_CORE}/${props.item.img}`}
				/>
				<div className='est-category-text-global-container'>
					<h2
						className={`${
							isShow ? 'category-name-shown' : 'category-name-unshown'
						} category-name`}>
						{props.item.nombre}
					</h2>
					<div
						className={`${
							isShow
								? 'est-category-text-main-container-shown'
								: 'est-category-text-main-container-unshown'
						} est-category-text-main-container`}>
						<h3 className='category-subtitle'>
							is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
							been the industry's standard dummy text ever since the 1500s, when an unknown
							printer took a galley
						</h3>
					</div>
					<div
						className={`${
							isShow ? 'category-link-shown' : 'category-link-unshown'
						} category-link`}>
						<ArrowRightOutlined className='category-link-icon' />
					</div>
				</div>
			</div>
		</div>
	)
}
