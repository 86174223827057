/** @format */

import React from 'react'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import CardCategories from './components/CardCategories'

import './style.css'

export default function Categories(props) {
	return (
		<div className='est-category-global-container'>
			<Row className='est-categories-text-container'>
				<Col>
					<h3 className='est-category-title-primary'>CATEGORIAS</h3>
					<h2 className='est-category-title-secondary'>TIPOS DE CAMPING</h2>
				</Col>
			</Row>
			<Row className='est-categories-map-container'>
				{props.categories.map((item, index) => (
					<Col xs={24} sm={24} md={12} lg={12} xl={6} key={index}>
						<div className='est-card-categories-global-container'>
							<CardCategories item={item} />
						</div>
					</Col>
				))}
			</Row>
		</div>
	)
}
