/** @format */

import React from 'react'
import { Route, Redirect } from 'react-router-dom'

class PrivatePublicate extends React.Component {
	render() {
		let isLogin = JSON.parse(localStorage.getItem('userSession'))
		if (isLogin) {
			if (isLogin.modo === 'directo') {
				return <Route {...this.props} />
			}
		}
		return <Redirect to='/' />
	}
}
export default PrivatePublicate
