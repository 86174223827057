/** @format */

export const rulesValidation = {
	rulesRequired: [
		{
			required: true,
			message: 'Por favor ingresa un valor',
		},
	],
	rulesNameRequired: [
		{
			required: true,
			message: 'Por favor ingresa un valor',
		},
		{
			min: 3,
			message: 'Mínimo 3 caracteres!',
		},
		{
			max: 32,
			message: 'Máximo 32 caracteres!',
		},
	],
	rulesAddressRequired: [
		{
			required: true,
			message: 'Por favor ingresa un valor',
		},
		{
			min: 2,
			message: 'Mínimo 3 caracteres!',
		},
		{
			max: 50,
			message: 'Máximo 50 caracteres!',
		},
	],
	rulesEmail: [
		{
			type: 'email',
			message: 'Correo no es válido',
		},
		{
			required: true,
			message: 'Por favor ingresa tu Correo',
		},
	],
	rulesPassword: [
		{
			required: true,
			message: 'Ingresa tu contraseña!',
		},
		{
			min: 4,
			message: 'Mínimo 4 caracteres!',
		},
	],
	rulesText: [
		{
			required: true,
			message: 'Campo obligatorio',
		},
		{
			min: 4,
			message: 'Mínimo 4 caracteres!',
		},
	],
	rulesFirstName: [
		{
			required: true,
			message: 'Ingresa tu nombre',
		},
		{
			min: 3,
			message: 'Mínimo 3 caracteres!',
		},
	],
	rulesLastName: [
		{
			required: true,
			message: 'Ingresa tu apellido',
		},
		{
			min: 3,
			message: 'Mínimo 3 caracteres!',
		},
	],
	rulesSpacer: [
		() => ({
			validator(_, value) {
				const validate = /^[A-Za-z0-9]+$/.test(value)
				if (value) {
					if (validate) {
						return Promise.resolve()
					} else {
						return Promise.reject(new Error('Formato no válido.'))
					}
				} else {
					return Promise.reject(new Error('Ingrese el No. de cupón.'))
				}
			},
		}),
	],
	rulesNumber: [
		() => ({
			validator(_, value) {
				const validate = /^[0-9]+$/.test(value)
				if (value?.replace(/[+()_/\s/]/g, '').length < 0) {
					return Promise.reject(new Error('Ingrese un numero.'))
				} else if (!validate) {
					return Promise.reject(new Error('Formato no válido.'))
				} else if (value?.replace(/[+()_/\s/]/g, '').length > 17) {
					return Promise.reject(new Error('Máximo 17 caracteres.'))
				} else return Promise.resolve()
			},
		}),
	],
	confirmPassword: [
		{
			required: true,
			message: '¡Por favor, confirme su contraseña!',
		},
		({ getFieldValue }) => ({
			validator(rule, value) {
				if (!value || getFieldValue('password') === value) {
					return Promise.resolve()
				}
				return Promise.reject('¡Las dos contraseñas que ingresó no coinciden!')
			},
		}),
	],
	confirmEmail: [
		{
			required: true,
			message: '¡Por favor, confirme su correo!',
		},
		({ getFieldValue }) => ({
			validator(rule, value) {
				if (!value || getFieldValue('email') === value) {
					return Promise.resolve()
				}
				return Promise.reject('¡Los dos correos no coinciden!')
			},
		}),
	],
}
