/** @format */

import React, { useState, useEffect } from 'react'
import { useGlobal, setGlobal } from 'reactn'
import { Link, useHistory } from 'react-router-dom'

import moment from 'moment'

import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'

import Tabs from 'antd/lib/tabs'

import HeadDescription from '../../../../components/Common/HeadDescription'
import Loading from '../../../../components/Common/Loading'

import { GetUserInfo } from '../../../../components/Common/Hooks/Functions/GetUserInfo'
import { CheckReservation } from '../../../../components/Common/Hooks/Functions/CheckReservation'

import MainMenu from '../../components/MainMenu'
import LoadingData from '../../components/LoadingData'

import UserReservations from './services'

import './style.css'

export default function UserListPublications() {
	const history = useHistory()
	const [isUser] = useState(JSON.parse(localStorage.getItem('userSession')))
	const [isDataProfile] = useGlobal('DataProfile')
	const [isUserReservations, setUserReservations] = useState(null)
	const [isUserReservationsLoading, setUserReservationsLoading] = useState(true)
	const [isPaymentLoading, setPaymentLoading] = useState(false)
	const { TabPane } = Tabs

	useEffect(() => {
		UserReservations(isUser.id).then((response) => {
			if (response) {
				setUserReservations(response)
			} else {
				setUserReservationsLoading(false)
			}
		})
		GetUserInfo(isUser.id).then((response) => {
			if (response) {
				setGlobal({
					DataProfile: response,
				})
			} else {
				setTimeout(() => {
					window.location.pathname = '/'
				}, 5000)
			}
		})
	}, [isUser.id])

	const handleCancelationDate = (item) => {
		let days
		if (item) {
			let todayDate = new Date()
			todayDate =
				todayDate.getFullYear() +
				'-' +
				0 +
				(todayDate.getMonth() + 1) +
				'-' +
				todayDate.getDate()
			todayDate = moment(todayDate)
			let cancellationDate = moment(item)
			days =
				'*(' +
				cancellationDate.diff(todayDate, 'days') +
				')' +
				' días restantes para realizar el pago.'
		}
		return days
	}

	const handlePayment = async (item) => {
		setPaymentLoading(true)
		await CheckReservation(item).then((response) => {
			setPaymentLoading(false)
			if (response) {
				history.push(`/profile/payment/${item}`)
			}
		})
	}

	if (!isDataProfile) {
		return <Loading />
	} else {
		return (
			<>
				<HeadDescription
					title={`Perfil de Usuario - ${isUser.nombre} - ${isUser.id}`}
					name={'description'}
					content={'Camping Site'}
				/>
				<div className='est-detail-profile-banner-global-container'>
					<Row className='est-detail-profile-banner-main-container'>
						<Col span={24} className='est-detail-profile-banner-title-container'>
							<h3 className='est-detail-profile-banner-title'>Reservaciones</h3>
						</Col>
						<Col className='est-detail-profile-banner-link-container'>
							<Link to='/' className='est-detail-profile-banner-link'>
								Home
							</Link>
							<h3 className='est-detail-profile-banner-slash'>/</h3>
							<Link to='/' className='est-detail-profile-banner-link'>
								Publicaciones
							</Link>
						</Col>
					</Row>
				</div>

				<div className='est-user-reservations-global-container'>
					<div className='est-user-reservations-main-container'>
						<div className='est-user-reservations-main-title-container'>
							<h2 className='est-user-reservations-main-title'>Información del usuario</h2>
						</div>

						<div className='est-profile-user-reservations-global-container'>
							<div className='est-profile-user-reservations-aside-container'>
								<MainMenu user={isDataProfile} />
							</div>
							<div className='est-profile-reservations-list-global-container'>
								<div className='est-profile-reservations-list-main-container'>
									<div className='est-profile-reservations-list-header-global-container'>
										<div className='est-profile-publications-list-button-container'>
											<Button
												className='est-profile-reservations-list-button'
												type='primary'
												onClick={() =>
													setGlobal({
														useDrawer: true,
													})
												}>
												Menú
											</Button>
										</div>
										<h2 className='est-profile-reservations-list-title'>
											Reservaciones:
										</h2>
									</div>
									<div className='est-profile-reservations-list-collapse-container'>
										{isUserReservationsLoading ? (
											<>
												{isUserReservations ? (
													<div className='est-user-reservations-list-global-container'>
														<Tabs defaultActiveKey='1'>
															<TabPane tab='Pendientes' key='1'>
																{isUserReservations.userReservationPending.length >
																0 ? (
																	<>
																		{isUserReservations.userReservationPending.map(
																			(item, index) => (
																				<div
																					key={index}
																					className='est-user-reservations-list-main-container'>
																					<Row>
																						<Col
																							xs={24}
																							sm={14}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									ID del anuncio:{' '}
																								</span>
																								{item.id_anuncio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Título:{' '}
																								</span>
																								{item.title}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={10}
																							lg={10}
																							xl={10}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Nombre del solicitante:{' '}
																								</span>
																								{item.nombre}{' '}
																								{item.apellido}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Dias Reservados:{' '}
																								</span>
																								{item.cantidad_dias}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Personas:{' '}
																								</span>
																								{item.cantidad_personas}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={10}
																							lg={10}
																							xl={10}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Fecha Inicio:{' '}
																								</span>
																								{item.fecha_desde}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Fecha Fin:{' '}
																								</span>
																								{item.fecha_hasta}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Subtotal:{' '}
																								</span>
																								${item.precio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={10}
																							lg={10}
																							xl={10}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Impuestos:{' '}
																								</span>
																								${item.impuesto}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Total:{' '}
																								</span>
																								${item.total}
																							</h4>
																						</Col>
																						<Col span={24}>
																							<div className='est-user-reservations-date-left'>
																								{handleCancelationDate(
																									item.fecha_vencimiento
																								)}
																							</div>
																						</Col>
																					</Row>
																				</div>
																			)
																		)}
																	</>
																) : (
																	<h3 className='est-user-reservations-list-tab-main-subtitle'>
																		No se encontraron registros
																	</h3>
																)}
															</TabPane>
															<TabPane tab='Aprobados' key='2'>
																{isUserReservations.userReservationApproved.length >
																0 ? (
																	<>
																		{isUserReservations.userReservationApproved.map(
																			(item, index) => (
																				<div
																					key={index}
																					className='est-user-reservations-list-main-container'>
																					<Row>
																						<Col
																							xs={24}
																							sm={14}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									ID del anuncio:{' '}
																								</span>
																								{item.id_anuncio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Título:{' '}
																								</span>
																								{item.title}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={10}
																							lg={10}
																							xl={10}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Nombre del solicitante:{' '}
																								</span>
																								{item.nombre}{' '}
																								{item.apellido}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Dias Reservados:{' '}
																								</span>
																								{item.cantidad_dias}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Personas:{' '}
																								</span>
																								{item.cantidad_personas}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={10}
																							lg={10}
																							xl={10}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Fecha Inicio:{' '}
																								</span>
																								{item.fecha_desde}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Fecha Fin:{' '}
																								</span>
																								{item.fecha_hasta}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Subtotal:{' '}
																								</span>
																								${item.precio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={10}
																							lg={10}
																							xl={10}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Impuestos:{' '}
																								</span>
																								${item.impuesto}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Total:{' '}
																								</span>
																								${item.total}
																							</h4>
																						</Col>
																						<Col span={24}>
																							<div className='est-user-reservations-date-left'>
																								{handleCancelationDate(
																									item.fecha_vencimiento
																								)}
																							</div>
																						</Col>
																						<Col span={24}>
																							<Button
																								loading={isPaymentLoading}
																								className='est-user-publications-list-reservations-button'
																								onClick={() =>
																									handlePayment(item.rowid)
																								}>
																								Pagar
																							</Button>
																						</Col>
																					</Row>
																				</div>
																			)
																		)}
																	</>
																) : (
																	<h3 className='est-user-reservations-list-tab-main-subtitle'>
																		No se encontraron registros
																	</h3>
																)}
															</TabPane>
															<TabPane tab='Canceladas' key='3'>
																{isUserReservations.userReservationCancel.length >
																0 ? (
																	<>
																		{isUserReservations.userReservationCancel.map(
																			(item, index) => (
																				<div
																					key={index}
																					className='est-user-reservations-list-main-container'>
																					<Row>
																						<Col
																							xs={24}
																							sm={14}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									ID del anuncio:{' '}
																								</span>
																								{item.id_anuncio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Título:{' '}
																								</span>
																								{item.title}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={10}
																							lg={10}
																							xl={10}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Nombre del solicitante:{' '}
																								</span>
																								{item.nombre}{' '}
																								{item.apellido}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Dias Reservados:{' '}
																								</span>
																								{item.cantidad_dias}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Personas:{' '}
																								</span>
																								{item.cantidad_personas}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={10}
																							lg={10}
																							xl={10}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Fecha Inicio:{' '}
																								</span>
																								{item.fecha_desde}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Fecha Fin:{' '}
																								</span>
																								{item.fecha_hasta}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Subtotal:{' '}
																								</span>
																								${item.precio}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={14}
																							md={10}
																							lg={10}
																							xl={10}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Impuestos:{' '}
																								</span>
																								${item.impuesto}
																							</h4>
																						</Col>
																						<Col
																							xs={24}
																							sm={10}
																							md={7}
																							lg={7}
																							xl={7}>
																							<h4 className='est-user-reservations-list-main-title'>
																								<span className='est-user-reservations-list-main-span-title'>
																									Total:{' '}
																								</span>
																								${item.total}
																							</h4>
																						</Col>
																					</Row>
																				</div>
																			)
																		)}
																	</>
																) : (
																	<h3 className='est-user-reservations-list-tab-main-subtitle'>
																		No se encontraron registros.
																	</h3>
																)}
															</TabPane>
														</Tabs>
													</div>
												) : (
													<LoadingData />
												)}
											</>
										) : (
											<h2 className='est-user-profile-reservations-list-main-title'>
												No se encontraron reservaciones
											</h2>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}
