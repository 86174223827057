/** @format */

import React, { useState } from 'react'
import { useGlobal, setGlobal } from 'reactn'

import { Link } from 'react-router-dom'

import Drawer from 'antd/lib/drawer'
import Button from 'antd/lib/button'
import Menu from 'antd/lib/menu'

import {
	HomeOutlined,
	PieChartOutlined,
	ReconciliationOutlined,
	ScheduleOutlined,
} from '@ant-design/icons'

import Image from '../../../../components/Common/Image'
import ProfileImg from '../../../../img/detail/profile-example.png'

import './style.css'

export default function MainMenu(props) {
	const [isCollapse, setCollapse] = useState(false)
	const [isVisible, setVisible] = useGlobal('useDrawer')

	const toggleCollapsed = () => {
		if (isCollapse) {
			setCollapse(false)
		} else {
			setCollapse(true)
		}
	}

	const onClose = () => {
		setVisible(false)
	}

	return (
		<>
			<div className='est-main-menu-collapse-container'>
				<Button
					type='primary'
					onClick={() => toggleCollapsed()}
					className={`${isCollapse ? '' : 'est-main-menu-button-shown'} est-main-menu-button`}>
					Menú
				</Button>
				<Menu defaultSelectedKeys={['1']} mode='inline' inlineCollapsed={isCollapse}>
					<Menu.Item key='1' className='est-detail-profile-name-img'>
						{props.user.foto ? (
							<Image
								classImg={`${
									isCollapse ? 'est-profile-img-unshown' : 'est-profile-img-shown'
								} est-profile-img`}
								image={props.user.foto}
								alt={'Profile photo'}
								title={'Profile photo'}
							/>
						) : (
							<Image
								classImg={`${
									isCollapse ? 'est-profile-img-unshown' : 'est-profile-img-shown'
								} est-profile-img`}
								image={ProfileImg}
								alt={'Profile photo'}
								title={'Profile photo'}
							/>
						)}
						<h3
							className={`${
								isCollapse ? 'est-main-menu-name-unshown' : ''
							} est-main-menu-name`}>
							{props.user.nombre} {props.user.apellido}
						</h3>
					</Menu.Item>

					<Menu.Item key='2' icon={<HomeOutlined />}>
						<Link to='/profile/user-data' className='est-main-menu-link'>
							Datos Personales
						</Link>
					</Menu.Item>

					<Menu.Item key='3' icon={<PieChartOutlined />}>
						<Link to='/profile/metrics' className='est-main-menu-link'>
							Metricas
						</Link>
					</Menu.Item>

					<Menu.Item key='4' icon={<ReconciliationOutlined />}>
						<Link to='/profile/user-publications' className='est-main-menu-link'>
							Mis Anuncios
						</Link>
					</Menu.Item>

					<Menu.Item key='5' icon={<ScheduleOutlined />}>
						<Link to='/profile/user-reservations' className='est-main-menu-link'>
							Mis Reservaciones
						</Link>
					</Menu.Item>
				</Menu>
			</div>

			<div className='est-main-menu-collapse-responsive-container'>
				<Drawer
					title='Menú'
					placement={'left'}
					closable={true}
					onClose={onClose}
					visible={isVisible}>
					<Menu defaultSelectedKeys={['1']} mode='inline' inlineCollapsed={isCollapse}>
						<Menu.Item key='1' className='est-detail-profile-name-img'>
							{props.user.foto ? (
								<Image
									classImg={`${
										isCollapse ? 'est-profile-img-unshown' : 'est-profile-img-shown'
									} est-profile-img`}
									image={props.user.foto}
									alt={'Profile photo'}
									title={'Profile photo'}
								/>
							) : (
								<Image
									classImg={`${
										isCollapse ? 'est-profile-img-unshown' : 'est-profile-img-shown'
									} est-profile-img`}
									image={ProfileImg}
									alt={'Profile photo'}
									title={'Profile photo'}
								/>
							)}

							<h3 className={'est-main-menu-name'}>
								{props.user.nombre} {props.user.apellido}
							</h3>
						</Menu.Item>

						<Menu.Item key='2' icon={<HomeOutlined />}>
							<Link
								to='/profile/user-data'
								className='est-main-menu-link'
								onClick={() =>
									setGlobal({
										useDrawer: false,
									})
								}>
								Datos Personales
							</Link>
						</Menu.Item>

						<Menu.Item key='3' icon={<PieChartOutlined />}>
							<Link
								to='/profile/metrics'
								className='est-main-menu-link'
								onClick={() =>
									setGlobal({
										useDrawer: false,
									})
								}>
								Metricas
							</Link>
						</Menu.Item>

						<Menu.Item key='4' icon={<ReconciliationOutlined />}>
							<Link
								to='/profile/user-publications'
								className='est-main-menu-link'
								onClick={() =>
									setGlobal({
										useDrawer: false,
									})
								}></Link>
							Mis Anuncios
						</Menu.Item>

						<Menu.Item key='5' icon={<ScheduleOutlined />}>
							<Link
								to='/profile/user-reservations'
								className='est-main-menu-link'
								onClick={() =>
									setGlobal({
										useDrawer: false,
									})
								}></Link>
							Mis reservaciones
						</Menu.Item>
					</Menu>
				</Drawer>
			</div>
		</>
	)
}
