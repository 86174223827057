/** @format */

import axios from 'axios'

import notification from 'antd/lib/notification'

import { ENV_CORE } from '../../../../../../components/Common/Hooks/Variables/Enviroment'

export default async function ProfileUpdate(item) {
	let returnResponse
	await axios({
		method: 'POST',
		url: `${ENV_CORE}/api/auth/update-user`,
		data: item,
	})
		.then((response) => {
			if (response.data.statusCode === 200) {
				notification['success']({
					message: 'Advertencia:',
					description: 'Datos actualizados correctamente',
				})
				returnResponse = response
			} else {
				notification['warning']({
					message: 'Advertencia:',
					description: 'Error al actializar sus datos del Perfil.',
				})
			}
		})
		.catch(() => {
			notification['error']({
				message: `Error`,
				description: 'Verifique su conexión a Internet',
			})
		})
	return returnResponse
}
